import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button, Typography } from '@material-ui/core';
import { useFormPessoaCadastroValidation } from './form-pessoa-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  ImagemInput,
  TextFieldSaurus
} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { useSessaoAtual } from 'services/app';
import { picker } from 'utils/picker';
import { PessoaPostModel } from 'model/api/gestao/pessoa/pessoa-post-model';
import ImagemPadrao from 'assets/img/usuario-sem-imagem.png'
import { isEmpty } from 'lodash';
import { TipoCobrancaRevendaMock } from 'data/mocks/tipo-cobranca-revenda-mock';
import { EnumTipoCobrancaRevenda } from 'model/enums/enum-tipo-cobranca-revenda';
import { AutocompleteRevendas } from 'views/components/controles/autocompletes/autocomplete-revendas/autocomplete-revendas';
import { AutocompleteGerentes } from 'views/components/controles/autocompletes/autocomplete-gerentes/autocomplete-gerentes';
import { useStyles } from '../pessoa-edicao/form-pessoa-edicao-styles'
import DomToImage from 'dom-to-image';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { AutocompleteVendedor } from 'views/components/controles/autocompletes/autocomplete-vendedor/autocomplete-vendedor';

interface PessoaFormModelProps extends DefaultFormProps<PessoaPostModel> {
  revenda?: boolean;
  isRevendedor?: boolean;
}

export const FormPessoaCadastro = forwardRef<
  DefaultFormRefs<PessoaPostModel>,
  PessoaFormModelProps
>(
  (
    { loading, onSubmit, revenda = false, isRevendedor, ...props }: PessoaFormModelProps,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const refInputNome = useRef<HTMLInputElement>(null);
    const { FormPessoaCadastroValidationYup } = useFormPessoaCadastroValidation(revenda)
    // const [pessoaMock, setPessoaMock] = useState<KeyValueModel[]>([])
    const [modelForm, setModelForm] = useState<PessoaPostModel>(new PessoaPostModel())
    const { usuario, tipoUsuario } = useSessaoAtual()
    const [revendedorId, setRevendedorId] = useState<string>('')
    const pinRef = useRef<HTMLDivElement>(null)
    const [img, setImg] = useState<string>('')
    const classes = useStyles()
    const [loadingPin, setLoadingPin] = useState<boolean>(false)
    const { theme } = useThemeQueries();

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      getValues,
      setValue,
    } = useForm<PessoaPostModel>({
      resolver: yupResolver(FormPessoaCadastroValidationYup),
      defaultValues: modelForm,
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    useEffect(() => { }, [revendedorId])

    const carregando =
      loading

    // const getPessoaMock = useCallback(async () => {
    //   const res = await getPessoa(1, `pageSize=0&tipo=${EnumTipoPessoas.Revendedor}`)

    //   if (res.erro) throw res.erro

    //   const ret = res.resultado?.data.list as PessoasModel[]

    //   const mock = ret
    //     .map(item => new KeyValueModel(item.id, item.nomeComercial))

    //   setPessoaMock(mock)
    //   reset({ ...modelForm })
    // }, [getPessoa, modelForm, reset])

    // useEffect(() => {
    //   if (!isRevendedor && !revenda) {
    //     getPessoaMock()
    //   }
    // }, [getPessoaMock, isRevendedor, revenda])

    const ImagemBlobParaBase64 = (arquivo: Blob): Promise<{ base64: string, extensao: string }> => {

      return new Promise((resolve) => {

        if (!arquivo) {

          resolve({ base64: "", extensao: "" });

        }

        const reader = new FileReader();

        reader.onload = (event) => {
          const base64 = event.target?.result?.toString() || "";
          const extensao = arquivo.type.split("/")[1];
          resolve({ base64, extensao });
        };
        reader.readAsDataURL(arquivo);

      });

    }


    const createMarker = useCallback(async () => {
      try {
        pinRef.current!.style.display = 'flex'
        const pinImg = await DomToImage.toBlob(pinRef.current!, {
          height: 70,
          width: 53
        })
        const b64 = await ImagemBlobParaBase64(pinImg)
        pinRef.current!.style.display = 'none'
        return b64.base64
      } catch (e: any) {
        pinRef.current!.style.display = 'none'
        return ''
      }
    }, [])

    const submitAlterarDadosConta = async (values: PessoaPostModel) => {
      const pessoa = picker<PessoaPostModel>(values, new PessoaPostModel())

      if (!isEmpty(img)) {
        setLoadingPin(true)
        pessoa.urlImagemPin = await createMarker();
        setLoadingPin(false)
      }
      if (!revenda) {
        pessoa.melhorDia = null
        pessoa.status = null
      } else {
        pessoa.melhorDia = 5
      }
      onSubmit(pessoa);
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submitAlterarDadosConta)();
      },
      resetForm: () => {
        reset();
        if (!isMobile) refInputNome.current?.focus();
      },
      fillForm: (model: PessoaPostModel) => {
        if (tipoUsuario() !== EnumTipoPessoas.FinanceiroSoftwareHouse && tipoUsuario() !== EnumTipoPessoas.SoftwareHouse) {
          model.revendaId = (tipoUsuario() === EnumTipoPessoas.Revendedor ? usuario?.Id : usuario?.RevendaId) || ''
        } else if (isEmpty(model.revendaId)) {
          model.revendaId = null
        }
        if (revenda && model.tipoCobrancaRevenda === null) model.tipoCobrancaRevenda = EnumTipoCobrancaRevenda.ClienteFinal
        setModelForm(model)
        setImg(model.urlImagem)
        reset({ ...model });
        if (!isMobile) refInputNome.current?.focus();
      },
    }));

    const imagemBase64 = useCallback(() => {
      try {
        return atob(getValues('urlImagem'))
      } catch (e: any) {
        return getValues('urlImagem')
      }
    }, [getValues])

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer}>
            {carregando && props.showLoading && (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="FULLSIZED" />
              </div>
            )}

            <form
              onSubmit={handleSubmit(submitAlterarDadosConta)}
              className={loading ? utilClasses.controlLoading : ''}
            >
              <Grid container spacing={2} justifyContent="center">
                {revenda && tipoUsuario() !== EnumTipoPessoas.Revendedor && (
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Controller
                      name="urlImagem"
                      control={control}
                      render={({ field }) => (
                        <ImagemInput
                          loadErrorImage={ImagemPadrao}
                          accept='image/*'
                          imageRadius="50%"
                          width={isMobile ? '180px' : '200px'}
                          height={isMobile ? '180px' : '200px'}
                          error={Boolean(
                            errors.urlImagem && errors.urlImagem.message,
                          )}
                          helperText={
                            touchedFields.urlImagem || errors.urlImagem
                              ? errors.urlImagem?.message
                              : undefined
                          }
                          {...field}
                          value={imagemBase64()}
                          onChange={({ base64 }: any) => {
                            setValue('urlImagem', base64);
                            setImg(base64)
                          }}
                          onBlur={() => {
                            setValue('urlImagem', '');
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controller
                    name="cpfCnpj"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        inputRef={refInputNome}
                        disabled={loading}
                        allowSubmit={false}
                        tipo="CNPJ_CPF"
                        label="CPF/CNPJ"
                        fullWidth
                        manterMascara
                        autoComplete={'off'}
                        helperText={
                          errors.cpfCnpj
                            ? errors.cpfCnpj.message
                            : undefined
                        }
                        error={Boolean(errors.cpfCnpj && errors.cpfCnpj.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="nomeComercial"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        disabled={loading}
                        allowSubmit={false}
                        id="nome"
                        label="Nome"
                        fullWidth
                        autoComplete={'off'}
                        helperText={
                          errors.nomeComercial
                            ? errors.nomeComercial.message
                            : undefined
                        }
                        error={Boolean(errors.nomeComercial && errors.nomeComercial.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                {revenda && (
                  <Grid item xs={12}>
                    <Controller
                      name="telefoneSuporte"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={loading}
                          allowSubmit={false}
                          id="telefoneSuporte"
                          label="Telefone de Suporte"
                          fullWidth
                          tipo='TELEFONE'
                          autoComplete={'off'}
                          helperText={
                            errors.telefoneSuporte
                              ? errors.telefoneSuporte.message
                              : undefined
                          }
                          error={Boolean(errors.telefoneSuporte && errors.telefoneSuporte.message)}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                )}
                {(!isRevendedor && !revenda && tipoUsuario() !== EnumTipoPessoas.FuncionarioFinanceiro && tipoUsuario() !== EnumTipoPessoas.Representante) && (
                  <Grid item xs={12}>
                    <Controller
                      name="revendaId"
                      control={control}
                      render={() => (
                        <AutocompleteRevendas
                          label='Revendedor'
                          value={getValues('revendaId') || undefined}
                          onChange={(e) => {
                            setValue('revendaId', e.value.id)
                            setRevendedorId(e.value.id)
                          }}
                          helperText={
                            errors.revendaId
                              ? errors.revendaId.message
                              : undefined
                          }
                          error={Boolean(errors.revendaId && errors.revendaId.message)}
                        />
                        // <SelectSaurus
                        //   disabled={loading}
                        //   label="Revendedor"
                        //   conteudo={pessoaMock}
                        //   fullWidth
                        //   helperText={
                        //     touchedFields.revendaId && errors.revendaId
                        //       ? errors.revendaId.message
                        //       : undefined
                        //   }
                        //   error={Boolean(errors.revendaId && errors.revendaId.message)}
                        //   {...field}
                        // />
                      )}
                    />
                  </Grid>
                )}
                {(!isRevendedor && revenda) && (
                  <Grid item xs={12}>
                    <Controller
                      name="gerenteId"
                      control={control}
                      render={() => (
                        <AutocompleteGerentes
                          label='Gerente Comercial'
                          value={getValues('gerenteId') || undefined}
                          onChange={(e) => {
                            setValue('gerenteId', e.value.id)
                          }}
                          helperText={
                            errors.gerenteId
                              ? errors.gerenteId.message
                              : undefined
                          }
                          error={Boolean(errors.gerenteId && errors.gerenteId.message)}
                        />
                      )}
                    />
                  </Grid>
                )}
                {(!revenda && tipoUsuario() !== EnumTipoPessoas.Representante) && (
                  <Grid item xs={12}>
                    <Controller
                      name="vendedorId"
                      control={control}
                      render={({ field }) => (
                        <AutocompleteVendedor
                          label='Vendedor (Opcional)'
                          revendedorId={getValues('revendaId') || undefined}
                          value={getValues('vendedorId') || undefined}
                          fetchOnLoad
                          onChange={(e: any) => {
                            setValue('vendedorId', e.value.id)
                          }}
                          helperText={
                            errors.vendedorId
                              ? errors.vendedorId.message
                              : undefined
                          }
                          error={Boolean(errors.vendedorId && errors.vendedorId.message)}
                        />
                      )}
                    />
                  </Grid>
                )}
                {revenda && (
                  <Grid item xs={12}>
                    <Controller
                      name="tipoCobrancaRevenda"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          disabled={loading}
                          label="Tipo de Faturamento"
                          conteudo={TipoCobrancaRevendaMock}
                          fullWidth
                          helperText={
                            errors.tipoCobrancaRevenda
                              ? errors.tipoCobrancaRevenda.message
                              : undefined
                          }
                          error={Boolean(errors.tipoCobrancaRevenda && errors.tipoCobrancaRevenda.message)}
                          {...field}
                          onChange={e => {
                            const item = TipoCobrancaRevendaMock.find(x => x.Key === e.target.value)
                            if (item) {
                              setValue('tipoCobrancaRevenda', item.Key)
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
                {revenda && (
                  <Grid item xs={12}>
                    <Controller
                      name="recebeIndicacoes"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={loading}
                          allowSubmit={false}
                          id='recebeIndicacoes'
                          label='Quilometragem Maxima da Indicação (KM)'
                          tipo='NUMERO'
                          fullWidth
                          autoComplete={'off'}
                          helperText={
                            errors.recebeIndicacoes
                              ? errors.recebeIndicacoes.message
                              : undefined
                          }
                          error={Boolean(errors.recebeIndicacoes && errors.recebeIndicacoes.message)}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
              {loadingPin && <Box className={classes.loadingPinContainer}>
                <Box className={classes.loadingPin}>
                  <div ref={pinRef} className={classes.pinContainer}>
                    <div
                      className={classes.pin}
                      style={{ zIndex: 999 }}
                    >
                      <img
                        src={!isEmpty(img) ? img : SemImagem}
                        alt="Imagem do marcador"
                      />
                    </div>
                  </div>
                  <Typography color='textSecondary' variant='caption' style={{ marginTop: theme.spacing(2) }}>Carregando Imagem...</Typography>
                </Box>
              </Box>}
            </form>
          </div>
        </Box>
      </>
    );
  },
);
