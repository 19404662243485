import { Box, Button, Fade, Grid, Tooltip } from '@material-ui/core';
import { TpFiltrarPorMock } from 'data/mocks/tp-filtrar-por-mock';
import { isEmpty } from 'lodash';
import { EnumFiltrarPor } from 'model/enums/enum-filtrar-por';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { AutocompleteComissionado } from 'views/components/controles/autocompletes/autocomplete-comissionado/autocomplete-comissionado';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { ProcurarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { ComissoesListSearchProps } from '../../comissoes-tabs/comissoes-list-search-props';
import { useStyles } from './pesquisa-comissoes-styles';

interface PesquisaHeaderProps {
  onPesquisar: (props: ComissoesListSearchProps) => any;
  onCloseSearch: () => void;
  searchProps: ComissoesListSearchProps;
  isOpened: boolean;
}

export default function PesquisaComissoes(props: PesquisaHeaderProps) {
  const { showToast } = useToastSaurus();
  const classes = useStyles();
  const { theme, isMobile, md } = useThemeQueries();
  const location = useLocation();
  const { tipoUsuario } = useSessaoAtual()

  const isSoftwareHouse = (tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse)


  const pesquisaInicial = useMemo<ComissoesListSearchProps>(() => {
    return {
      comissionadoId: '',
      comissionadoCpfCnpj: '',
      dPagFinal: '',
      dPagInic: '',
      dVencFinal: '',
      dVencIni: '',
      competenciaInicial: null,
      competenciaFinal: null
    };
  }, []);

  const [pesquisa, setPesquisa] =
    useState<ComissoesListSearchProps>(pesquisaInicial);
  const [filtrarPor, setFiltrarPor] = useState<EnumFiltrarPor>(EnumFiltrarPor.DATA_PAGAMENTO);

  const onChangePesquisa = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPesquisa((prev) => {
        return {
          ...prev,
          [event.target.name]: event.target.value,
        };
      });
    },
    [],
  );

  const pesquisar = useCallback(
    (event?: any, searchProps?: ComissoesListSearchProps) => {
      if (event) event.preventDefault();

      if (
        new Date(pesquisa.dPagInic) > new Date(pesquisa.dPagFinal) ||
        (!isEmpty(pesquisa.dVencIni) &&
          !isEmpty(pesquisa.dVencFinal) &&
          new Date(pesquisa.dVencFinal!) < new Date(pesquisa.dVencIni!))
      ) {
        showToast('error', 'Data Inicial é maior do que a data final.');
        return;
      }
      props.onPesquisar(searchProps || pesquisa);
    },
    [pesquisa, props, showToast],
  );

  useEffect(() => {
    setPesquisa({
      comissionadoId: '',
      comissionadoCpfCnpj: '',
      dPagFinal: '',
      dPagInic: '',
      dVencFinal: '',
      dVencIni: '',
      competenciaInicial: null,
      competenciaFinal: null
    });
  }, [location.pathname]);

  const layoutRevenda = useMemo(() => (
    <Grid container spacing={2}>
      {isSoftwareHouse && <Grid item xs={6} md={6} lg={filtrarPor === null ? 6 : 3}>
        <AutocompleteComissionado
          value={
            isEmpty(pesquisa.comissionadoId)
              ? props.searchProps.comissionadoId
              : pesquisa.comissionadoId
          }
          label="Comissionado"
          size="small"
          name="comissionadoId"
          onChange={(e) => {
            setPesquisa((prev) => ({
              ...prev,
              comissionadoId: e.value.id,
            }));
          }}
        />
      </Grid>}
      <Grid item xs={6} md={4} lg={filtrarPor === null ? 6 : 3}>
        <SelectSaurus
          label="Filtrar por"
          conteudo={TpFiltrarPorMock}
          size="small"
          onChange={(ev) => {
            const item = TpFiltrarPorMock.filter(
              (item) => item.Key === ev.target.value,
            );
            setFiltrarPor(item[0].Key)
          }}
          defaultValue={EnumFiltrarPor.DATA_PAGAMENTO}
        />
      </Grid>
      {filtrarPor === EnumFiltrarPor.DATA_PAGAMENTO && (
        <>
          <Grid item xs={6} md={4} lg={2}>
            <TextFieldSaurus
              name="dPagInic"
              fullWidth
              label="Data de Pgto. Inicial"
              size="small"
              tipo="DATA"
              allowSubmit={true}
              value={pesquisa.dPagInic}
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>

          <Grid item xs={6} md={4} lg={2}>
            <TextFieldSaurus
              name="dPagFinal"
              fullWidth
              label="Data de Pgto. Final"
              size="small"
              tipo="DATA"
              allowSubmit={true}
              value={pesquisa.dPagFinal}
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
        </>
      )}
      {filtrarPor === EnumFiltrarPor.DATA_VENCIMENTO && (
        <>
          <Grid item xs={6} md={3} lg={2}>
            <TextFieldSaurus
              name="dVencIni"
              fullWidth
              label="Data de Venc. Inicial"
              size="small"
              tipo="DATA"
              allowSubmit={true}
              value={pesquisa.dVencIni}
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <TextFieldSaurus
              name="dVencFinal"
              fullWidth
              label="Data de Venc. Final"
              size="small"
              tipo="DATA"
              allowSubmit={true}
              value={pesquisa.dVencFinal}
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
        </>
      )}

      <Grid item xs={6} md={4} lg={2}>
        <TextFieldSaurus
          name="competenciaInicial"
          fullWidth
          label="Competência Inicial"
          size="small"
          tipo="NUMERO"
          allowSubmit={true}
          value={pesquisa.competenciaInicial}
          onChange={onChangePesquisa}
          adornmentColor={theme.palette.text.disabled}
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2}>
        <Box className={classes.containerFinal}>
          <TextFieldSaurus
            name="competenciaFinal"
            fullWidth
            label="Competência Final"
            size="small"
            tipo="NUMERO"
            allowSubmit={true}
            value={pesquisa.competenciaFinal}
            onChange={onChangePesquisa}
            adornmentColor={theme.palette.text.disabled}
          />
        </Box>
      </Grid>
      <Grid item xs={6} md={4} lg={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <Tooltip title='Pesquisar' style={{ width: '100%' }}>
          <Button color="primary" variant="contained" type="submit">
            <ProcurarIcon tipo="BUTTON_PRIMARY" style={(!isMobile || !md) ? { margin: 0 } : undefined} />
            {(isMobile || md) ? 'Pesquisar' : ''}
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  ), [classes.containerFinal, filtrarPor, isMobile, isSoftwareHouse, md, onChangePesquisa, pesquisa.comissionadoId, pesquisa.competenciaFinal, pesquisa.competenciaInicial, pesquisa.dPagFinal, pesquisa.dPagInic, pesquisa.dVencFinal, pesquisa.dVencIni, props.searchProps.comissionadoId, theme.palette.text.disabled])

  const layoutSoftwareHouse = useMemo(() => (
    <Grid container spacing={2}>
      {isSoftwareHouse && <Grid item xs={6} md={4}>
        <AutocompleteComissionado
          value={
            isEmpty(pesquisa.comissionadoId)
              ? props.searchProps.comissionadoId
              : pesquisa.comissionadoId
          }
          label="Comissionado"
          size="small"
          name="comissionadoId"
          onChange={(e) => {
            setPesquisa((prev) => ({
              ...prev,
              comissionadoId: e.value.id,
            }));
          }}
        />
      </Grid>}
      <Grid item xs={6} md={4}>
        <TextFieldSaurus
          name="competenciaInicial"
          fullWidth
          label="Competência Inicial"
          size="small"
          tipo="NUMERO"
          allowSubmit={true}
          value={pesquisa.competenciaInicial}
          onChange={onChangePesquisa}
          adornmentColor={theme.palette.text.disabled}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <Box className={classes.containerFinal}>
          <TextFieldSaurus
            name="competenciaFinal"
            fullWidth
            label="Competência Final"
            size="small"
            tipo="NUMERO"
            allowSubmit={true}
            value={pesquisa.competenciaFinal}
            onChange={onChangePesquisa}
            adornmentColor={theme.palette.text.disabled}
          />
        </Box>
      </Grid>
      <Grid item xs={6} md={3}>
        <SelectSaurus
          label="Filtrar por"
          conteudo={TpFiltrarPorMock}
          size="small"
          onChange={(ev) => {
            const item = TpFiltrarPorMock.filter(
              (item) => item.Key === ev.target.value,
            );
            setFiltrarPor(item[0].Key)
          }}
          defaultValue={EnumFiltrarPor.DATA_PAGAMENTO}
        />
      </Grid>
      {filtrarPor === EnumFiltrarPor.DATA_PAGAMENTO && (
        <>
          <Grid item xs={6} md={3}>
            <TextFieldSaurus
              name="dPagInic"
              fullWidth
              label="Data de Pgto. Inicial"
              size="small"
              tipo="DATA"
              allowSubmit={true}
              value={pesquisa.dPagInic}
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <TextFieldSaurus
              name="dPagFinal"
              fullWidth
              label="Data de Pgto. Final"
              size="small"
              tipo="DATA"
              allowSubmit={true}
              value={pesquisa.dPagFinal}
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
        </>
      )}
      {filtrarPor === EnumFiltrarPor.DATA_VENCIMENTO && (
        <>
          <Grid item xs={6} md={3}>
            <TextFieldSaurus
              name="dVencIni"
              fullWidth
              label="Data de Venc. Inicial"
              size="small"
              tipo="DATA"
              allowSubmit={true}
              value={pesquisa.dVencIni}
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextFieldSaurus
              name="dVencFinal"
              fullWidth
              label="Data de Venc. Final"
              size="small"
              tipo="DATA"
              allowSubmit={true}
              value={pesquisa.dVencFinal}
              onChange={onChangePesquisa}
              adornmentColor={theme.palette.text.disabled}
            />
          </Grid>
        </>
      )}
      <Grid item xs={6} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
        <Tooltip title='Pesquisar' style={{ width: '100%' }}>
          <Button color="primary" variant="contained" type="submit">
            <ProcurarIcon tipo="BUTTON_PRIMARY" />
            Pesquisar
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  ), [classes.containerFinal, filtrarPor, isSoftwareHouse, onChangePesquisa, pesquisa.comissionadoId, pesquisa.competenciaFinal, pesquisa.competenciaInicial, pesquisa.dPagFinal, pesquisa.dPagInic, pesquisa.dVencFinal, pesquisa.dVencIni, props.searchProps.comissionadoId, theme.palette.text.disabled])

  return (
    <Fade in={props.isOpened} mountOnEnter unmountOnExit>
      <form onSubmit={pesquisar} className={classes.root}>
        {isSoftwareHouse ? layoutSoftwareHouse : layoutRevenda}
      </form>
    </Fade >
  );
}
