import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { useCallback, useState, useEffect, useMemo } from 'react';
import { useStyles } from './form-contrato-styles';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { EnumTipoCalculo, KeyValueModel } from 'model';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import {
  ContratoFormAlternativoModel,
  ContratoFormModuloCardModel,
  ContratoFormModuloModel,
} from 'model/app/forms/contrato/contrato-form-model';
import { isEmpty } from 'lodash';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { calcPercent } from 'utils/calc-percent';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { guidEmpty } from 'utils/guid-empty';
import { CancelarIcon } from 'views/components/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { toDateStringApi } from 'utils/to-date';
import { useFormContratoSegundaFaseValidation } from './form-contrato-segunda-fase-validation';
import { validarCPFCNPJ } from 'utils/cpfcnpj-validate';
import { EnumTipoCobrancaRevenda } from 'model/enums/enum-tipo-cobranca-revenda';
import { useConfirm } from 'material-ui-confirm';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { EnumTipoControleModulo } from 'model/enums/enum-tipo-controle-modulo';
import { useGetNovoContrato } from 'data/api/gestao/contratos/get-novo-contrato';
import { TabelaPrecoContratoModel } from 'model/api/gestao/contrato/contrato-dados-model';
import { PlanoModel } from 'model/api/gestao/plano/plano-model';
import { toCurrency } from 'utils/toCurrency';
import CardModulosContrato from 'views/components/cards/card-modulos-contrato/card-modulos-contrato';
import { ValoresFinais } from '../contrato-edicao/form-contrato-editar';

interface Props extends DefaultFormProps<ContratoFormAlternativoModel> {
  pessoaId: string;
  revendaId: string;
  erro: string | null;
  setErro: (value: string | null) => void;
  disable: Array<boolean>;
  setDisable: React.Dispatch<React.SetStateAction<boolean[]>>;
  sistemaId: string;
  temVendedor: boolean;
}

export class ValoresState {
  constructor(
    public valorContrato: number = 0,
    public valorCusto: number = 0,
    public valorCalculado: number = 0,
    public valorCustoOriginal: number = 0,
    public valorDesconto: number = 0,
  ) { }
}

export const FormContratoCadastro = React.forwardRef<
  DefaultFormRefs<ContratoFormAlternativoModel>,
  Props
>((props: Props, refs) => {
  const { getNovoContrato, carregando: carregandoGet } = useGetNovoContrato()


  const { showToast } = useToastSaurus();

  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const confirm = useConfirm();
  const { usuario, tipoUsuario } = useSessaoAtual();

  const [ambienteMock, setAmbienteMock] = useState<KeyValueModel[]>([])
  const [tipoCobranca, setTipoCobranca] = useState<EnumTipoCobrancaRevenda>(usuario?.TipoCobrancaRevenda || EnumTipoCobrancaRevenda.Revenda)

  const isTipoRevenda = tipoCobranca === EnumTipoCobrancaRevenda.Revenda || tipoCobranca === EnumTipoCobrancaRevenda.RevendaPagas

  const podeVerCusto = useMemo(() => (tipoUsuario() !== EnumTipoPessoas.Representante), [tipoUsuario])


  const { FormContratoSegundaFaseValidationYup } = useFormContratoSegundaFaseValidation({ isRetaguarda: props.sistemaId === 'b2a22b5d-b681-43e1-b110-44bc2f8809b9', isTipoRevenda, ambiente: ambienteMock.length > 0 });

  const [model, setModel] = useState<ContratoFormAlternativoModel>(
    new ContratoFormAlternativoModel(),
  );
  const [tabelasPreco, setTabelasPreco] = useState<TabelaPrecoContratoModel[]>([]);
  const [tabelaPreco, setTabelaPreco] = useState<TabelaPrecoContratoModel>(
    new TabelaPrecoContratoModel(),
  );
  const [precosModulosCard, setPrecosModulosCard] = useState<
    ContratoFormModuloCardModel[]
  >([]);
  const [planosMock, setPlanosMock] = useState<KeyValueModel[]>([]);
  const [planos, setPlanos] = useState<PlanoModel[]>([])
  const [planoId, setPlanoId] = useState<string>('');

  const [ambiente, setAmbiente] = useState<string>('')

  const [pagamentoMock, setPagamentoMock] = useState<KeyValueModel[]>([])

  const [valoresState, setValoresState] = useState<ValoresState>(new ValoresState())

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm<ContratoFormAlternativoModel>({
    resolver: yupResolver(FormContratoSegundaFaseValidationYup),
    defaultValues: { ...model },
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const { isMobile, theme } = useThemeQueries();

  const carregando = carregandoGet;

  const isRetaguarda = getValues('sistemaId') === 'b2a22b5d-b681-43e1-b110-44bc2f8809b9'

  const onSubmit = useCallback((form: ContratoFormAlternativoModel) => {
    form.modulos = precosModulosCard.map(
      (item) =>
        new ContratoFormModuloModel(
          guidEmpty(),
          item.modulo.id,
          item.quantidadeContratada,
          item.modulo.codigo,
        ),
    );

    form.clienteId = props.pessoaId;
    form.tabelaPrecoId = tabelaPreco.tabelaPrecoId;
    form.dataExpiracao = form.dataExpiracao ? toDateStringApi(new Date(form.dataExpiracao)) : null;
    form.valorContrato = toDecimal(form.valorContrato, 2)
    form.valorCalculado = toDecimal(form.valorCalculado, 2)
    if (precosModulosCard.find(modulo => (modulo.quantidadeContratada > modulo.modulo.quantidadeMaxima ||
      modulo.quantidadeContratada < modulo.modulo.quantidadeMinima) && (modulo.modulo.quantidadeMinima > -1 && modulo.modulo.quantidadeMaxima > -1))) {
      showToast('error', 'Módulos com quantidade incorretas.')
      return
    }

    if (form.dataExpiracao) {
      form.dataExpiracao = toDateStringApi(new Date(form.dataExpiracao))
    }

    if (isTipoRevenda) {
      form.formaPagamentoId = null;
      form.valorCalculado = form.valorCusto;
      form.valorContrato = form.valorCusto;
    }

    form.melhorDia = 5
    props.onSubmit(form, model);
  }, [isTipoRevenda, model, precosModulosCard, props, showToast, tabelaPreco.tabelaPrecoId]);

  const validarModulosDependentes = useCallback(() => {
    const modulosDependentes = precosModulosCard.filter(modulo => modulo.modulo.tipoCalculo === EnumTipoCalculo.Modulo && modulo.modulo.tipoControle !== EnumTipoControleModulo.Filial && modulo.quantidadeContratada > 0)
    let modulosZerados: string[] = []

    modulosDependentes.forEach(modulo => {
      const moduloPai = precosModulosCard.find(mod => mod.modulo.codigo.toString() === modulo.modulo.codModuloBaseCalculo)
      if (moduloPai!.quantidadeContratada < 1) {
        if (modulosZerados.includes(moduloPai!.modulo.nome)) return
        modulosZerados = [...modulosZerados, moduloPai!.modulo.nome]
      }
    })
    if (modulosZerados.length > 0) {
      showToast('error', `Você possuí módulos selecionados que dependem de módulos não selecionados.
      Módulos Dependentes: ${modulosDependentes.reduce<string>((prev, curr) => isEmpty(prev) ? curr.modulo.nome : `${prev}, ${curr.modulo.nome}`, '')}
      Módulos não selecionados: ${modulosZerados.reduce((prev, curr) => `${prev}, ${curr}`)}`, 8000)
    }

    return modulosZerados.length > 0
  }, [precosModulosCard, showToast])

  const confirmSubmit = useCallback((form: ContratoFormAlternativoModel) => {
    if (validarModulosDependentes()) return
    confirm({
      title: 'Criar Contrato',
      description: (
        <Box display='flex' flexDirection='column' gridGap={theme.spacing(2)}>
          {(toDecimal(form.valorContrato) < form.valorCusto) && <Typography color='error'>ATENÇÃO: O valor do seu contrato é inferior ao valor de custo!</Typography>}
          <Typography>Deseja criar este contrato? Valor do {isTipoRevenda ? 'custo' : 'contrato'}: <b>{toCurrency(toDecimal(isTipoRevenda ? form.valorCusto : form.valorContrato))}</b></Typography>
        </Box>
      ),
      cancellationText: 'Cancelar',
      confirmationText: 'Salvar'
    }).then(() => onSubmit(form))
  }, [confirm, isTipoRevenda, onSubmit, theme, validarModulosDependentes])

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(confirmSubmit)();
    },
    resetForm: () => {
      setModel(new ContratoFormAlternativoModel());
      reset();
    },
    fillForm: (model: ContratoFormAlternativoModel) => {
      setModel(model);
      reset({ ...model });
    },
  }));

  // //ISSO É TEMPORÁRIO ATÉ O TIPO DE COBRANÇA VIR NO GET DE CONTRATO DADOS
  // const getRevendaByIdWrapper = useCallback(async () => {
  //   const res = await getPessoaById(props.revendaId)

  //   if (res.erro) throw res.erro
  //   if (!res.resultado) throw new Error('Erro ao carregar dados. Tente novamente.')

  //   const ret = res.resultado.data as PessoaModel;

  //   setTipoCobranca(ret.tipoCobrancaRevenda)
  // }, [getPessoaById, props.revendaId])
  // //

  const contratoDadosWrapper = useCallback(async () => {
    try {
      const res = await getNovoContrato(props.pessoaId, props.sistemaId)

      if (res.erro) throw res.erro
      if (!res.resultado) throw new Error('Erro ao carregar dados. Tente novamente.')

      //Caso o revendedor não tenha configurado a tabela de preços
      if (isEmpty(res.resultado.data.precos.tabelas)) {
        throw new Error('É necessário configurar uma tabela de preço para este sistema.')
      }

      //Setando Mocks
      const ambientesRevenda = res.resultado.data.ambientes.filter(ambiente => ambiente.revendaId === props.revendaId)
      const keyValueRevenda = ambientesRevenda.length > 0 ? [new KeyValueModel(-5, 'Meus Ambientes'), ...ambientesRevenda.map(ambiente => new KeyValueModel(ambiente.id, ambiente.nome))] : []

      const ambientesGlobais = res.resultado.data.ambientes.filter(ambiente => ambiente.revendaId !== props.revendaId)
      const keyValueGlobais = ambientesGlobais.length > 0 ? [new KeyValueModel(-5, 'Ambientes Globais'), ...ambientesGlobais.map(ambiente => new KeyValueModel(ambiente.id, ambiente.nome))] : []

      setAmbienteMock([...keyValueRevenda, ...keyValueGlobais])
      setPagamentoMock(res.resultado.data.pagamentos.map(pagamento => new KeyValueModel(pagamento.id, pagamento.nome)))
      setPlanosMock(res.resultado.data.planos.map(plano => new KeyValueModel(plano.id, plano.nome)))
      setPlanos(res.resultado.data.planos)

      setTabelaPreco(res.resultado.data.precos.tabelas[0])
      setTabelasPreco(res.resultado.data.precos.tabelas)
      setValue('tabelaPrecoId', res.resultado.data.precos.tabelas[0].tabelaPrecoId)
      setTipoCobranca(res.resultado.data.tipoCobrancaRevenda)

    } catch (e: any) {
      if (e.message === 'Configure uma tabela de preço para seguir com o contrato' && props.temVendedor) {
        showToast('error', 'Você não possui uma tabela de preço configurada para o vendedor. Crie uma tabela vinculada com o vendedor deste cliente para criar o contrato.')
        return
      }
      showToast('error', e.message)
    }
  }, [getNovoContrato, props.pessoaId, props.revendaId, props.sistemaId, props.temVendedor, setValue, showToast])


  const alterarQtdModulo = useCallback(
    (model: ContratoFormModuloCardModel) => {
      let modulo = [...precosModulosCard];
      const ret = modulo.find((item) => item.modulo.id === model.modulo.id);

      const index = modulo.indexOf(ret || new ContratoFormModuloCardModel());
      modulo[index] = model;
      setPrecosModulosCard(modulo);
    },
    [precosModulosCard],
  );

  const alterarQtd = useCallback(
    (model: ContratoFormModuloCardModel, qtde: number) => {
      const newState = Object.assign({}, model);

      newState.quantidadeContratada = qtde;
      alterarQtdModulo(newState);
    },
    [alterarQtdModulo],
  );

  useEffect(() => {
    if (!props.erro) {
      contratoDadosWrapper();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (!isEmpty(tabelaPreco) && !isEmpty(tabelaPreco.precoModulos)) {
      const contemModulo = tabelaPreco.precoModulos.filter((pm) => {
        const foundValue = precosModulosCard.find(
          (md) => md.modulo.id === pm.modulo.id,
        );

        return !isEmpty(foundValue);
      });

      if (contemModulo?.length !== precosModulosCard.length) {
        setPlanoId('');
        setValue('planoId', null);
      }

      setPrecosModulosCard(
        tabelaPreco.precoModulos.map((item) => {
          return {
            modulo: item.modulo,
            percAdicional: item.percAdicional,
            percAdicionalCusto: item.percAdicionalCusto,
            quantidadeContratada: item.modulo.quantidadePadrao === -1 ? 0 : item.modulo.quantidadePadrao,
            valor: item.valor,
            valorCustoOriginal: item.valorCustoOriginal,
            valorCusto: item.valorCusto,
            editavel: false,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, tabelaPreco, tabelaPreco.precoModulos]);

  const valoresFinais = precosModulosCard.map((item) => {
    let quantMult = item.quantidadeContratada;
    let quantMult2 = item.quantidadeContratada;
    let quantMult3 = item.quantidadeContratada;
    let valorFinal = item.valor;
    let valorCustoOriginal = item.valorCustoOriginal;
    let valorCusto = item.valorCusto;
    if (item.quantidadeContratada === -1) {
      return {
        ...item,
        valorFinal: 0,
        valorCusto: 0,
        valorCustoOriginal: 0
      };
    }

    if (item.quantidadeContratada > 1) {
      quantMult =
        toDecimal((item.quantidadeContratada - 1) *
          calcPercent(item.valor, isTipoRevenda ? item.percAdicionalCusto : item.percAdicional), 6);
      quantMult2 =
        toDecimal((item.quantidadeContratada - 1) *
          calcPercent(item.valorCusto, item.percAdicionalCusto), 6);
      quantMult3 =
        toDecimal((item.quantidadeContratada - 1) *
          calcPercent(item.valorCustoOriginal, item.percAdicionalCusto), 6);
    }

    if (item.quantidadeContratada === 0) {
      valorFinal = 0;
      valorCustoOriginal = 0;
      valorCusto = 0;
    }
    else if (item.quantidadeContratada < 2) {
      valorFinal = item.valor;
      valorCustoOriginal = item.valorCustoOriginal;
      valorCusto = item.valorCusto;
    } else {
      valorFinal = valorFinal + quantMult;
      valorCustoOriginal = valorCustoOriginal + quantMult3;
      valorCusto = valorCusto + quantMult2;
    }

    if (item.modulo.tipoCalculo === EnumTipoCalculo.Modulo && item.modulo.tipoControle === EnumTipoControleModulo.Filial) {
      item.modulo.codModuloBaseCalculo = item.modulo.codModuloBaseCalculo.toString()
      const codsBase = item.modulo.codModuloBaseCalculo.includes(',') ? item.modulo.codModuloBaseCalculo.split(',').map(cod => Number(cod)) : item.modulo.codModuloBaseCalculo.split('-').map(cod => Number(cod))
      const moduloBase = precosModulosCard.filter(mod => codsBase.includes(mod.modulo.codigo))

      const valorBase = toDecimal(moduloBase.reduce((prev, curr) => {
        if (curr.quantidadeContratada === 0) return prev
        if (curr.quantidadeContratada === 1) {
          return curr.valor + prev
        }
        return toDecimal((curr.quantidadeContratada - 1) * calcPercent(curr.valor, curr.percAdicional) + curr.valor + prev, 6)
      }, 0))

      const valorBaseCustoOriginal = toDecimal(moduloBase.reduce((prev, curr) => {
        if (curr.quantidadeContratada === 0) return prev
        if (curr.quantidadeContratada === 1) {
          return curr.valorCustoOriginal + prev
        }
        return toDecimal((curr.quantidadeContratada - 1) * calcPercent(curr.valor, curr.percAdicionalCusto) + curr.valorCustoOriginal + prev, 6)
      }, 0))

      const valorBaseCusto = moduloBase.reduce((prev, curr) => {
        if (curr.quantidadeContratada === 0) return prev
        if (curr.quantidadeContratada === 1) {
          return curr.valorCusto + prev
        }
        return toDecimal((curr.quantidadeContratada - 1) * calcPercent(curr.valorCusto, curr.percAdicionalCusto) + curr.valorCusto + prev, 6)
      }, 0)


      valorFinal = moduloBase.length > 0 && item.quantidadeContratada > 0 ? toDecimal((item.quantidadeContratada) * calcPercent(valorBase, item.percAdicional), 6) : valorFinal
      valorCustoOriginal = moduloBase.length > 0 && item.quantidadeContratada > 0 ? toDecimal((item.quantidadeContratada) * calcPercent(valorBaseCustoOriginal, item.percAdicionalCusto), 6) : valorCustoOriginal
      valorCusto = moduloBase.length > 0 && item.quantidadeContratada > 0 ? toDecimal((item.quantidadeContratada) * calcPercent(valorBaseCusto, item.percAdicionalCusto), 6) : valorCusto
    } else if (item.modulo.tipoCalculo === EnumTipoCalculo.Modulo) {
      const codBase = Number(item.modulo.codModuloBaseCalculo)
      const moduloBase = precosModulosCard.find(mod => mod.modulo.codigo === codBase)

      valorFinal = moduloBase ? valorFinal * (moduloBase?.quantidadeContratada || 1) : valorFinal
      valorCusto = moduloBase ? valorCusto * (moduloBase?.quantidadeContratada || 1) : valorCusto
      valorCustoOriginal = moduloBase ? valorCustoOriginal * (moduloBase.quantidadeContratada || 1) : valorCustoOriginal
    }

    return {
      valorFinal: valorFinal,
      valorCusto: valorCusto,
      valorCustoOriginal: valorCustoOriginal
    };
  });

  useEffect(() => {
    if (!props.erro) {
      // try catch pra retirar o erro que não estava entendendo o que estava acontecendo
      try {
        const { vCalculado, vContrato, vCusto, vCustoOriginal } = valoresFinais.reduce<ValoresFinais>((prev: ValoresFinais, curr: any) => {
          const newRetorno = { ...prev }

          newRetorno.vCalculado += curr.valorFinal
          newRetorno.vCusto += curr.valorCusto
          newRetorno.vContrato += curr.valorFinal
          newRetorno.vCustoOriginal += curr.valorCustoOriginal

          return newRetorno
        }, new ValoresFinais())

        setValue('valorCalculado', vCalculado)
        setValue('valorContrato', vContrato)
        setValue('valorCusto', vCusto)

        setValoresState(prev => ({
          valorCalculado: vCalculado,
          valorCustoOriginal: vCustoOriginal,
          valorContrato: vContrato,
          valorCusto: vCusto,
          valorDesconto: prev.valorDesconto
        }))

        validateFields()
      } catch {

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [precosModulosCard])

  const setValores = useCallback((event: any) => {
    if (toDecimal(event.target.value) < 0 || event.target.value.includes('-')) return

    setValue(event.target.name, event.target.value);

    const valorDesconto = toDecimal(
      event.target.name === 'valorDesconto'
        ? event.target.value
        : getValues('valorDesconto'), 4
    );

    const valorContrato = toDecimal(
      event.target.name === 'valorContrato' ? event.target.value : getValues('valorContrato'), 4
    )

    if (event.target.name === 'valorDesconto') {
      setValue(
        'valorContrato',
        toDecimal(valoresFinais.reduce((a: any, b: any) => a + b.valorFinal, 0) -
          valorDesconto, 4)
      );
      return;
    }

    if (event.target.name === 'valorContrato') {
      setValue(
        'valorDesconto',
        toDecimal(toDecimal(getValues('valorCalculado'), 4) - valorContrato, 4) < 0 ? (
          0
        ) : (
          toDecimal(toDecimal(getValues('valorCalculado'), 4) - valorContrato, 4)
        )
      );

      setValoresState(prev => ({
        ...prev,
        valorContrato: toDecimal(event.target.value)
      }))
    }
  }, [getValues, setValue, valoresFinais])

  const validateFields = useCallback(() => {
    const valorContrato = toDecimal(getValues('valorContrato'), 4);
    const valorTabelado = toDecimal(getValues('valorCalculado'), 4)
    if (
      valorContrato < valorTabelado &&
      !props.disable[1] && !podeVerCusto
    ) {
      setError('valorContrato', {
        type: 'error',

      });
      props.setDisable((prev) => [prev[0], true, prev[2]]);
    } else if (
      valorContrato >= valorTabelado &&
      props.disable[1]
    ) {
      clearErrors('valorContrato');
      props.setDisable((prev) => [prev[0], false, prev[2]]);
    }
  }, [clearErrors, getValues, podeVerCusto, props, setError]);

  const selecionarPlano = useCallback(async (item: KeyValueModel) => {
    const plano = planos.find(plano => plano.id === item.Key) || new PlanoModel()

    setPrecosModulosCard((prev) => {
      const ret = plano.modulos;
      const modulosNaoPlanos = prev
        .filter(
          (item) =>
            !ret.map((fill) => fill.moduloId).includes(item.modulo.id),
        )
        .map((item) => ({
          ...item,
          editavel: true,
        }));
      const temPlanos = plano.modulos.map((item) => {
        const valoresPrev = prev.filter(
          (pr) => pr.modulo.id === item.moduloId,
        )[0];

        return {
          ...valoresPrev,
          quantidadeContratada: item!.quantidadeContratada,
          editavel: false,
        };
      });
      return [...modulosNaoPlanos, ...temPlanos];
    });
    setValue('planoId', item.Key);
    setPlanoId(item.Key);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      model.tabelaPrecoId,
      setValue,
      showToast,
      tabelaPreco,
      tabelasPreco,
    ],
  );

  const removePlano = useCallback(() => {
    setPlanoId('');
    setValue('planoId', null);
  }, [setValue]);

  // const quantidadeDias = () => {
  //   let dias: Array<number> = [5, 20, 25, 30];
  //   return dias.map((item) => new KeyValueModel(item, item === 30 ? `${item} (Desabilitado)` : item.toString()));
  // };

  const formatarDominio = useCallback((e: any) => {
    let dominio: string = e.target.value.replaceAll('-', ' ');
    const caracteresEspeciaisRegex = /[^\w\s]/gi;

    if (caracteresEspeciaisRegex.test(dominio)) {
      props.setDisable(prev => [prev[0], true, prev[2]])
      setError('dominio', { type: 'error', message: 'Caracteres especiais não são permitidos' })
    } else if (props.disable[1]) {
      clearErrors('dominio')
      props.setDisable(prev => [prev[0], false, prev[2]])
    }

    if (validarCPFCNPJ(dominio)) {
      setError('dominio', { type: 'error', message: 'Não é permitido CPF ou CNPJ no domínio.' })
      props.setDisable(prev => [prev[0], true, prev[2]])
    }

    dominio = dominio.replaceAll(' ', '-').toLowerCase()

    setValue('dominio', dominio)

  }, [clearErrors, props, setError, setValue])

  const valorLucro = useCallback(() => {
    const vLucro = toDecimal(valoresState.valorContrato - valoresState.valorCusto);
    const percLucro = toDecimal(((vLucro / valoresState.valorCusto) * 100))

    return `${toCurrency(vLucro)} (%${isNaN(percLucro) ? 0 : toDecimalString(percLucro)})`
  }, [valoresState])

  //Memos para validar exibição dos campos (decidi criar um memo para organizar e validar as grids dos campos, porque os ternários estavam poluindo mt o código)

  const camposSuperiores = useMemo(() => {

    let campos = <></>;

    const gridDominio = !isTipoRevenda || ambienteMock.length > 0 ? 6 : 12
    const gridFormaPagamento = isRetaguarda || ambienteMock.length > 0 ? 6 : 12
    const gridAmbiente = (!isTipoRevenda && isRetaguarda) || (isTipoRevenda && !isRetaguarda) ? 12 : 6

    if (isRetaguarda) {
      campos = <>
        {campos}
        <Grid item lg={gridDominio} xs={12}>
          <Controller
            name="dominio"
            control={control}
            render={({ field }) => (
              <TextFieldSaurus
                fullWidth
                variant="outlined"
                label="Dominio"
                size='small'
                {...field}
                helperText={
                  errors.dominio
                    ? errors.dominio.message
                    : undefined
                }
                error={Boolean(
                  errors.dominio && errors.dominio.message,
                )}
                onBlur={formatarDominio}
              />
            )}
          />
        </Grid>

      </>
    }

    if (!isTipoRevenda) {
      campos = <>
        {campos}
        <Grid item lg={gridFormaPagamento} xs={12}>
          <Controller
            name="formaPagamentoId"
            control={control}
            render={({ field }) => (
              <SelectSaurus
                label="Forma de Pagamento"
                conteudo={pagamentoMock}
                size='small'
                helperText={
                  errors.formaPagamentoId
                    ? errors.formaPagamentoId.message
                    : undefined
                }
                error={Boolean(
                  errors.formaPagamentoId && errors.formaPagamentoId.message,
                )}
                {...field}
                onChange={(ev) => {
                  const item = pagamentoMock.filter(
                    (item) => item.Key === ev.target.value,
                  );

                  setValue('formaPagamentoId', item[0].Key);
                }}
              />
            )}
          />
        </Grid>
      </>
    }

    if (ambienteMock.length > 0) {
      campos = <>
        {campos}
        <Grid item lg={gridAmbiente} xs={12}>
          <Controller
            name="ambienteId"
            control={control}
            render={({ field }) => (
              <SelectSaurus
                conteudo={ambienteMock}
                disabled={isEmpty(ambienteMock)}
                fullWidth
                variant="outlined"
                label="Ambiente"
                size='small'
                {...field}
                value={ambiente}
                onChange={(event) => {
                  if (event) {
                    const item = ambienteMock.filter(
                      (item) =>
                        item.Key === event.target.value,
                    )[0];
                    if (item) {
                      setAmbiente(item.Key)
                      setValue('ambienteId', item.Key);
                      return
                    }
                    setAmbiente('')
                    setValue('ambienteId', '')
                  }
                }}
                helperText={
                  errors.ambienteId
                    ? errors.ambienteId.message
                    : undefined
                }
                error={Boolean(
                  errors.ambienteId && errors.ambienteId.message,
                )}
              />
            )}
          />
        </Grid>
      </>
    }
    return campos
  }, [ambiente, ambienteMock, control, errors.ambienteId, errors.dominio, errors.formaPagamentoId, formatarDominio, isRetaguarda, isTipoRevenda, pagamentoMock, setValue])

  //--------------------------------


  if (!carregando && props.erro) {
    return (
      <>
        <div
          className={utilClasses.formContainer}
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <div>
              <CancelarIcon
                tipo="GERAL"
                fill="#D22"
                style={{
                  width: 150,
                }}
              />
            </div>
            <Typography variant="h4" color="primary">
              Ocorreu um Erro
            </Typography>
            <Typography variant="body1" color="textPrimary">
              Detalhe: {props.erro}
            </Typography>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={utilClasses.formContainer}
        style={{
          height: '100%',
        }}
      >
        {carregando || props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            background: '#fff',
            padding: !isMobile ? '1rem 1rem 0 1rem' : '10px',
          }}
          className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
            }`}
        >
          <Grid container spacing={3} style={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}>
            <Grid item xs={12} lg={8} style={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2)
            }}>
              <Grid
                container
                spacing={1}
                style={{
                  flex: '1 1 auto',
                  position: 'relative',
                }}
                className={classes.modulosContainer}
              >
                {!isMobile && <>
                  <Grid item xs={12} md={planosMock.length > 0 ? 6 : 12}>
                    <Controller
                      name="tabelaPrecoId"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          conteudo={tabelasPreco.map(
                            (item) =>
                              new KeyValueModel(item.tabelaPrecoId, item.nome),
                          )}
                          fullWidth
                          variant="outlined"
                          label="Tabela de Preços"
                          size='small'
                          {...field}
                          value={getValues('tabelaPrecoId')}
                          onChange={(event) => {
                            if (event) {
                              const item = tabelasPreco.filter(
                                (item) =>
                                  item.tabelaPrecoId === event.target.value,
                              )[0];
                              if (item) {
                                setTabelaPreco(item);
                                setValue('tabelaPrecoId', item.tabelaPrecoId);
                              }
                            }
                          }}
                          helperText={
                            touchedFields.tabelaPrecoId && errors.tabelaPrecoId
                              ? errors.tabelaPrecoId.message
                              : undefined
                          }
                          error={Boolean(
                            errors.tabelaPrecoId && errors.tabelaPrecoId.message,
                          )}
                        />
                      )}
                    />
                  </Grid>
                  {planosMock.length > 0 && <Grid item xs={12} md={6}>
                    <Controller
                      name="planoId"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          conteudo={
                            [new KeyValueModel(-1, 'Não utilizar plano'), new KeyValueModel(-5, ''), ...planosMock]
                          }
                          fullWidth
                          variant="outlined"
                          label="Plano (opcional)"
                          size='small'
                          {...field}
                          value={planoId}
                          onChange={(event) => {
                            if (event) {
                              const item = planosMock.filter(
                                (item) => item.Key === event.target.value,
                              )[0];
                              if (item) {
                                selecionarPlano(item);
                              } else {
                                removePlano()
                              }
                            }
                          }}
                          helperText={
                            touchedFields.planoId && errors.planoId
                              ? errors.planoId.message
                              : undefined
                          }
                          error={Boolean(
                            errors.planoId && errors.planoId.message,
                          )}
                        />
                      )}
                    />
                  </Grid>}
                </>}
                <Grid item xs={12}>
                  <Typography variant='h6' color='textPrimary'>Módulos</Typography>
                </Grid>
                <Grid item xs={12} className={classes.modulosScroll}>
                  {precosModulosCard.sort((a, b) => a.modulo.codigo - b.modulo.codigo).map((item) => {
                    return (
                      <CardModulosContrato
                        hasPlanoId={
                          !isEmpty(planoId || planoId !== guidEmpty())
                        }
                        alterarQtd={alterarQtd}
                        model={item}
                        modulos={precosModulosCard}
                        key={item.modulo.id}
                        isTipoRevenda={isTipoRevenda}

                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              {isMobile && <Grid container
                spacing={2} style={{ paddingBottom: theme.spacing(1) }}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="tabelaPrecoId"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        conteudo={tabelasPreco.map(
                          (item) =>
                            new KeyValueModel(item.tabelaPrecoId, item.nome),
                        )}
                        fullWidth
                        variant="outlined"
                        label="Tabela de Preços"
                        size='small'
                        {...field}
                        value={getValues('tabelaPrecoId')}
                        onChange={(event) => {
                          if (event) {
                            const item = tabelasPreco.filter(
                              (item) =>
                                item.tabelaPrecoId === event.target.value,
                            )[0];
                            if (item) {
                              setTabelaPreco(item);
                              setValue('tabelaPrecoId', item.tabelaPrecoId);
                            }
                          }
                        }}
                        helperText={
                          touchedFields.tabelaPrecoId && errors.tabelaPrecoId
                            ? errors.tabelaPrecoId.message
                            : undefined
                        }
                        error={Boolean(
                          errors.tabelaPrecoId && errors.tabelaPrecoId.message,
                        )}
                      />
                    )}
                  />
                </Grid>
                {planosMock.length > 0 && <Grid item xs={12} md={6}>
                  <Controller
                    name="planoId"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        conteudo={
                          [new KeyValueModel(-1, 'Não utilizar plano'), new KeyValueModel(-5, ''), ...planosMock]
                        }
                        fullWidth
                        variant="outlined"
                        label="Plano (opcional)"
                        size='small'
                        {...field}
                        value={planoId}
                        onChange={(event) => {
                          if (event) {
                            const item = planosMock.filter(
                              (item) => item.Key === event.target.value,
                            )[0];
                            if (item) {
                              selecionarPlano(item);
                            } else {
                              removePlano()
                            }
                          }
                        }}
                        helperText={
                          touchedFields.planoId && errors.planoId
                            ? errors.planoId.message
                            : undefined
                        }
                        error={Boolean(
                          errors.planoId && errors.planoId.message,
                        )}
                      />
                    )}
                  />
                </Grid>}
              </Grid>}
              <Grid container spacing={2}>
                {camposSuperiores}
                <Grid item xs={12}>
                  <Divider style={{ background: theme.palette.divider }} />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Controller
                    name="dataExpiracao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Data de Expiração"
                        tipo="DATA"
                        fullWidth
                        manterMascara
                        helperText={
                          touchedFields.dataExpiracao && errors.dataExpiracao
                            ? errors.dataExpiracao.message
                            : undefined
                        }
                        error={Boolean(
                          errors.dataExpiracao && errors.dataExpiracao.message,
                        )}
                        {...field}
                        value={getValues('dataExpiracao') ? getValues('dataExpiracao') : null}
                      />
                    )}
                  />
                </Grid>
                {(podeVerCusto && isTipoRevenda) && <Grid item xs={5} md={7} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div>
                    <Typography variant='caption' color='textSecondary'>Valor Custo</Typography>
                    <div className={classes.valorDescontoBox}>
                      <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{toCurrency(valoresState.valorCusto)}</Typography>
                      {valoresState.valorCusto < valoresState.valorCustoOriginal && <Typography variant='caption'>{toCurrency(valoresState.valorCustoOriginal)}</Typography>}
                    </div>
                  </div>
                </Grid>}
                <Grid item xs={12} md={(podeVerCusto && isTipoRevenda) ? 12 : 7}>
                  <Box className={classes.greyContainer} style={{ fontSize: 12 }}>
                    Informe a data ao lado caso seja necessário uma data limite de acesso ao sistema. Após a data o bloqueio será realizado
                  </Box>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="valor"
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Valor Vigente"
                        size="small"
                        readOnly
                        tipo="DECIMAL"
                        showStartAdornment
                        manterMascara
                        helperText={
                          touchedFields.valor && errors.valor
                            ? errors.valor.message
                            : undefined
                        }
                        error={Boolean(errors.valor && errors.valor.message)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="valorCalculado"
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Valor Calculado"
                        size="small"
                        readOnly
                        tipo="DECIMAL"
                        showStartAdornment
                        manterMascara
                        helperText={
                          touchedFields.valorCalculado && errors.valorCalculado
                            ? errors.valorCalculado.message
                            : undefined
                        }
                        error={Boolean(
                          errors.valorCalculado && errors.valorCalculado.message,
                        )}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="valorDesconto"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="R$ Desconto"
                        size="small"
                        tipo="DECIMAL"
                        showStartAdornment
                        manterMascara
                        helperText={
                          touchedFields.valorDesconto && errors.valorDesconto
                            ? errors.valorDesconto.message
                            : undefined
                        }
                        error={Boolean(
                          errors.valorDesconto && errors.valorDesconto.message,
                        )}
                        {...field}
                        onChange={(ev) => setValores(ev)}
                        onBlur={validateFields}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="valorContrato"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Valor Contrato"
                        size="small"
                        showStartAdornment
                        {...field}
                        value={getValues('valorContrato')}
                        tipo="DECIMAL"
                        helperText={
                          errors.valorContrato
                            ? errors.valorContrato.message
                            : undefined
                        }
                        error={Boolean(
                          errors.valorContrato && errors.valorContrato.message,
                        )}
                        manterMascara
                        onChange={(ev) => {
                          setValores(ev)
                        }}
                        onBlur={validateFields}
                      />
                    )}
                  />
                </Grid>
                {tipoUsuario() !== EnumTipoPessoas.FuncionarioFinanceiro && (
                  <Grid item xs={12}>
                    <Controller
                      name="valorCusto"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          readOnly
                          label="Valor Custo Final"
                          size="small"
                          showStartAdornment
                          {...field}
                          value={getValues('valorCusto')}
                          tipo="DECIMAL"
                          helperText={
                            touchedFields.valorCusto && errors.valorCusto
                              ? errors.valorCusto.message
                              : undefined
                          }
                          error={Boolean(
                            errors.valorCusto && errors.valorCusto.message,
                          )}
                          manterMascara
                        />
                      )}
                    />
                  </Grid>
                )}
                {!isTipoRevenda && <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="melhorDia"
                    render={({ field }) => (
                      <SelectSaurus
                        label="Melhor Dia"
                        size="small"
                        conteudo={quantidadeDias()}
                        helperText={
                          errors.melhorDia
                            ? errors.melhorDia.message
                            : undefined
                        }
                        error={Boolean(
                          errors.melhorDia && errors.melhorDia.message,
                        )}
                        {...field}
                        onChange={(ev: any) => {
                          if (ev.target.value === 30) {
                            return
                          }
                          const item = quantidadeDias().filter(
                            (item) => item.Key === ev.target.value,
                          );

                          setValue('melhorDia', item[0].Key);
                        }}
                      />
                    )}
                  />
                </Grid>}
                <Grid item xs={12} md={6}>
                  <Controller
                    name="dataExpiracao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Data de Expiração"
                        size="small"
                        tipo="DATA"
                        manterMascara
                        helperText={
                          touchedFields.dataExpiracao && errors.dataExpiracao
                            ? errors.dataExpiracao.message
                            : undefined
                        }
                        error={Boolean(
                          errors.dataExpiracao && errors.dataExpiracao.message,
                        )}
                        {...field}
                      // value={moment(getValues('dataExpiracao')).format('yyyy-MM-DD')}
                      />
                    )}
                  />
                </Grid> */}
                {!isTipoRevenda && <Grid item xs={6} style={{ borderRight: `2px solid ${theme.palette.primary.main}`, marginTop: theme.spacing(3) }}>
                  <Box display='flex' flexDirection='column' gridGap={theme.spacing(2)} ml={2}>
                    <div>
                      <Typography variant='caption' color='textSecondary'>Valor Tabelado</Typography>
                      <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{toCurrency(valoresState.valorCalculado)}</Typography>
                    </div>
                    {podeVerCusto && <div style={{ maxWidth: 150, maxHeight: 47 }}>
                      <Controller
                        name="valorContrato"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            label="Valor do Contrato"
                            showStartAdornment
                            {...field}
                            value={getValues('valorContrato')}
                            inputProps={{
                              style: {
                                fontSize: '1.1rem',
                                fontWeight: 500,
                              }
                            }}
                            tipo="DECIMAL"
                            helperText={
                              errors.valorContrato
                                ? errors.valorContrato.message
                                : undefined
                            }
                            error={Boolean(
                              errors.valorContrato,
                            )}
                            manterMascara
                            onChange={(ev) => {
                              setValores(ev)
                            }}
                            onBlur={validateFields}
                          />
                        )}
                      />
                    </div>}
                  </Box>
                </Grid>}
                <Grid item xs={6} style={{ marginTop: theme.spacing(3) }}>
                  <Box display='flex' flexDirection='column' gridGap={theme.spacing(2)} ml={2}>
                    {podeVerCusto && !isTipoRevenda ? (
                      <>
                        <div>
                          <Typography variant='caption' color='textSecondary'>Valor Custo</Typography>
                          <div className={classes.valorDescontoBox}>
                            <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{toCurrency(valoresState.valorCusto)}</Typography>
                            {valoresState.valorCusto < valoresState.valorCustoOriginal && <Typography variant='caption'>{toCurrency(valoresState.valorCustoOriginal)}</Typography>}
                          </div>
                        </div>
                        {!isTipoRevenda && <div>
                          <Typography variant='caption' color='textSecondary'>Lucro</Typography>
                          <Typography style={{ fontSize: '1.1rem', fontWeight: 500, color: '#148EE5' }}>{valorLucro()}</Typography>
                        </div>}
                      </>
                    ) : !isTipoRevenda && (
                      <div style={{ maxWidth: 150, maxHeight: 47 }}>
                        <Controller
                          name="valorContrato"
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              label="Valor do Contrato"
                              showStartAdornment
                              {...field}
                              value={getValues('valorContrato')}
                              inputProps={{
                                style: {
                                  fontSize: '1.1rem',
                                  fontWeight: 500,
                                }
                              }}
                              tipo="DECIMAL"
                              helperText={
                                errors.valorContrato
                                  ? errors.valorContrato.message
                                  : undefined
                              }
                              error={Boolean(
                                errors.valorContrato,
                              )}
                              manterMascara
                              onChange={(ev) => {
                                setValores(ev)
                              }}
                              onBlur={validateFields}
                            />
                          )}
                        />
                      </div>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
