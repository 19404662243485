import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { RelatorioSistemaModel } from 'model/api/gestao/relatorios/sistema-relatorio-model';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GestaoStorageKeys, useGestaoStorage, useSessaoAtual, useToastSaurus } from 'services/app';
import DashboarRevendaRelatorio from '../dashboard-revenda-relatorio/dashboard-revenda-relatorio';
import DashboarSistemasRelatorio from '../dashboard-sistemas-relatorio/dashboard-sistemas-relatorio';
import { LicenciamentoDBPrimary } from 'database/licenciamento-database';
import { PessoasMapaModel } from 'model/api/gestao/pessoa/pessoa-mapa-model';
import { isEmpty } from 'lodash';
import { IndicacoesModel } from 'model/api/gestao/indicacoes/indicacoes-model';
// import DashboardIndicacoesRelatorio from '../dashboard-indicacoes-relatorio/dashboard-indicacoes-relatorio';
import { RelatorioIndicacaoModel } from 'model/api/gestao/relatorios/relatorio-indicacoes-model';
// import DashboardUltimasIndicacoesRelatorio from '../dashboard-ultimas-indicacoes/dashboard-ultimas-indicacoes';
import DashboardMapa from '../dashboard-mapa/dashboard-mapa';
import { useThemeQueries } from 'views/theme';
import { RelatorioSearchPropsModel } from 'model/api/gestao/relatorios/relatorio-search-props-model';
import { FormFiltroRelatorio } from 'views/components/form/master/filtro-relatorio/form-filtro-relatorio';
import { DefaultFormRefs } from 'views/components/form/utils';
import { ProcurarIcon } from 'views/components/icons';
import { ResultadoRevendaModel } from 'model/api/gestao/relatorios/resultado-revenda-model';
import { AccordionRevenda } from '../accordion-revenda/accordion-revenda';
import { AccordionRelatorioSaurus } from '../accordion-relatorio-saurus/accordion-relatorio-saurus';
import { AccordionGerente } from '../accordion-gerente/accordion-gerente';
// import { EnumStatusGeralIndicaco } from 'model/enums/enum-status-geral-indicacao';
import { SituacaoStatusIndicacaoMock } from 'data/mocks/situacao-status-indicacao-mock';

interface DashboardRelatorioProps {
    relatorioSistemas: RelatorioSistemaModel[];
    relatorioIndicacoes: RelatorioIndicacaoModel[];
    indicacoes: IndicacoesModel[];
    getPessoasMapaWrapper: (ret: PessoasMapaModel[]) => Promise<any>;
    getIndicacoesWrapper: () => Promise<IndicacoesModel[]>;
    setRelatorioIndicacoes: React.Dispatch<React.SetStateAction<RelatorioIndicacaoModel[]>>;
    setIndicacoes: React.Dispatch<React.SetStateAction<IndicacoesModel[]>>;
    getResultadoRevendaApi: (model: RelatorioSearchPropsModel) => Promise<boolean>;
    carregando: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}


export default function DashboardRelatorio({ getResultadoRevendaApi, getPessoasMapaWrapper, getIndicacoesWrapper, setRelatorioIndicacoes, setIndicacoes, relatorioIndicacoes, relatorioSistemas, carregando, setLoading, indicacoes }: DashboardRelatorioProps) {


    const { tipoUsuario, usuario } = useSessaoAtual()

    const { getRegistro } = useGestaoStorage();

    const { theme } = useThemeQueries();
    const { showToast } = useToastSaurus();

    const [searchProps, setSearchProps] = useState<RelatorioSearchPropsModel>(new RelatorioSearchPropsModel())
    const [resultadoRevenda, setResultadoRevenda] = useState<ResultadoRevendaModel[]>([])
    const filtroRef = useRef<DefaultFormRefs<RelatorioSearchPropsModel>>(null)

    // const isAllowed = tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.GerenteComercial
    const isAllowed = tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse

    const isGerente = tipoUsuario() === EnumTipoPessoas.GerenteComercial
    const isRevenda = tipoUsuario() === EnumTipoPessoas.Revendedor
    // const isFuncionario = tipoUsuario() === EnumTipoPessoas.FuncionarioFinanceiro || tipoUsuario() === EnumTipoPessoas.Funcionario
    const isAdmin =
        tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse ||
        tipoUsuario() === EnumTipoPessoas.SoftwareHouse

    const getResultadoRevendaWrapper = useCallback(async (model: RelatorioSearchPropsModel) => {
        try {
            let res: ResultadoRevendaModel[] = []

            const periodoSalvo = getRegistro(GestaoStorageKeys.PeriodoRelatorio, false)
            if (periodoSalvo && model.anoMesInicial >= periodoSalvo.anoMesInicial && model.anoMesFinal >= periodoSalvo.anoMesFinal) {
                res = await LicenciamentoDBPrimary.resultadoRevenda.toArray() as ResultadoRevendaModel[]
            }
            if (isEmpty(res) || getRegistro(GestaoStorageKeys.RelatorioUsuario, false).usuarioId !== usuario?.Id) {
                const status = await getResultadoRevendaApi(model);
                if (status) {
                    await getResultadoRevendaWrapper(model);
                }
                return
            }
            res = res.filter(element => {
                const anoMesValido = model.anoMesInicial <= element.anoMes && model.anoMesFinal >= element.anoMes;

                return anoMesValido
            })
            setResultadoRevenda(res)

        } catch (e: any) {

        }
    }, [getRegistro, getResultadoRevendaApi, usuario?.Id])


    useEffect(() => {
        const getLocalData = async () => {
            setLoading(true)
            let localData = await LicenciamentoDBPrimary.clientes.toArray() as PessoasMapaModel[];
            let localIndicacoes = await LicenciamentoDBPrimary.indicacoes.toArray() as IndicacoesModel[];

            if (!isAllowed) {
                const revendaId = tipoUsuario() === EnumTipoPessoas.Revendedor ? usuario?.Id : usuario?.RevendaId

                localData = localData.filter(pessoa => pessoa.revendaId === revendaId)
            }

            //ESSA VALIDAÇÃO SERVE PARA TER CERTEZA QUE UM USUÁRIO QUE TROCA DE CONTA NÃO TENHA ACESSO AOS RELATÓRIOS DO OUTRO ACESSO
            if (getRegistro(GestaoStorageKeys.RelatorioUsuario, false).usuarioId !== usuario?.Id) {
                localData = []
                localIndicacoes = []
            }

            if (!isEmpty(localData)) {
                await getPessoasMapaWrapper(localData)
            } else {
                await getPessoasMapaWrapper([])
            }


            if (isEmpty(localIndicacoes)) {
                await getIndicacoesWrapper();
                return
            }

            const relatorio = localIndicacoes.map(item => new RelatorioIndicacaoModel(
                SituacaoStatusIndicacaoMock.find(x => x.Key === item.status)?.Value,
                1,
                item.status
            ))

            const dadosFinal = relatorio.reduce<RelatorioIndicacaoModel[]>((prev, curr) => {
                const equal = prev.find(x => x.status === curr.status)
                if (equal) {
                    const index = prev.indexOf(equal)

                    prev[index].quantidade += 1;
                    return prev
                }

                prev.push(curr)
                return prev
            }, [])
            setRelatorioIndicacoes(dadosFinal)
            setIndicacoes(localIndicacoes)

        }
        getLocalData().then(() => {
            getResultadoRevendaWrapper(searchProps).then(() => {
                setLoading(false)
            }).catch((e: any) => {
                setLoading(false)
                showToast('error', e.message)
            });
        })

    }, [getIndicacoesWrapper, getPessoasMapaWrapper, getRegistro, getResultadoRevendaWrapper, isAllowed, searchProps, setIndicacoes, setLoading, setRelatorioIndicacoes, showToast, tipoUsuario, usuario?.Id, usuario?.RevendaId])

    const handleSearch = useCallback((model: RelatorioSearchPropsModel) => {
        setSearchProps(model)
    }, [])

    useEffect(() => {
        filtroRef.current?.fillForm(searchProps)
    }, [searchProps])

    return (
        <>
            {
                isAdmin ? (
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={10}>
                                    <FormFiltroRelatorio
                                        loading={false}
                                        onSubmit={handleSearch}
                                        showLoading={false}
                                        ref={filtroRef}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        onClick={() => filtroRef.current?.submitForm()}
                                        fullWidth
                                    >
                                        <ProcurarIcon tipo='BUTTON_PRIMARY' />
                                        Filtrar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ) : null
            }

            <Grid item xs={12}>
                <Typography color='primary' variant='h6'>Sistemas</Typography>
                <Divider style={{ background: theme.palette.primary.main }} />
            </Grid>
            <Grid item xs={12} lg={12}>
                {relatorioSistemas.length > 0 || carregando ? <DashboarSistemasRelatorio list={relatorioSistemas} /> : (
                    <Typography variant='h6' color='textPrimary' align='center'>Não foram encontrados dados de sistemas.</Typography>
                )}
            </Grid>
            {
                isAdmin ? (
                    <>
                        {isAllowed && (
                            <>
                                <Grid item xs={12}>
                                    <Typography color='primary' variant='h6'>{isGerente ? 'Meus Dados' : 'Gerentes Comerciais'}</Typography>
                                    <Divider style={{ background: theme.palette.primary.main }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <AccordionGerente
                                        carregando={carregando}
                                        isGerente={isGerente}
                                        searchProps={searchProps}
                                        resultadoRevenda={resultadoRevenda}
                                    />
                                </Grid>
                            </>
                        )}
                        {/* {tipoUsuario() === EnumTipoPessoas.SoftwareHouse && ( */}
                        {isAdmin && (

                            <>
                                <Grid item xs={12}>
                                    <Typography color='primary' variant='h6'>Saurus</Typography>
                                    <Divider style={{ background: theme.palette.primary.main }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <AccordionRelatorioSaurus
                                        searchProps={searchProps}
                                        resultadoRevenda={resultadoRevenda}
                                    />
                                </Grid>
                            </>
                        )}
                        {/* {!isFuncionario && <> */}
                        {isAdmin && <>

                            <Grid item xs={12}>
                                <AccordionRevenda
                                    searchProps={searchProps}
                                    resultadoRevenda={resultadoRevenda}
                                    isRevenda={isRevenda}
                                    carregando={carregando}
                                />
                            </Grid>
                        </>}
                    </>
                ) : null
            }



            {isAllowed && <>
                <Grid item xs={12}>
                    <Typography color='primary' variant='h6'>Revendedores</Typography>
                    <Divider style={{ background: theme.palette.primary.main }} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DashboarRevendaRelatorio carregando={carregando} resultadoRevenda={resultadoRevenda} searchProps={searchProps} />
                        </Grid>
                    </Grid>
                </Grid>
            </>}
            <Grid item xs={12}>
                <Typography color='primary' variant='h6'>Clientes</Typography>
                <Divider style={{ background: theme.palette.primary.main }} />
            </Grid>
            <Grid item xs={12}>
                <DashboardMapa />
            </Grid>
        </>
    );
}
