import {
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { SituacaoMock } from 'data/mocks/situacao-mock';
import { EnumSituacao } from 'model/enums/enum-situacao';
import { useCallback } from 'react';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { useThemeQueries } from 'views/theme';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { CardFaturaFilhaProps } from './card-fatura-filha-props';
import { useStyles } from './card-fatura-filha-styles';
import { toCurrency } from 'utils/toCurrency';

export const CardFaturaFilha = ({
  model,
  selected,
  setSelected,
  esconderCheckbox,
  desabilitarCheckbox,
}: CardFaturaFilhaProps) => {
  const classes = useDefaultCardStyles();
  const listClasses = useStyles();
  const { theme, isMobile } = useThemeQueries();

  const retornarCorStatus = useCallback((): string => {
    switch (model.situacao) {
      case EnumSituacao.Aberta:
        return theme.palette.info.main;
      case EnumSituacao.Paga:
        return theme.palette.success.main;
      case EnumSituacao.Atraso:
        return theme.palette.warning.main;
      case EnumSituacao.Bonificada:
        return '#D7096C';
      case EnumSituacao.Cancelada:
        return theme.palette.error.main;
      case EnumSituacao.Fechada:
        return '#8346a6';
    }
    return theme.palette.info.main;
  }, [
    model.situacao,
    theme.palette.error.main,
    theme.palette.info.main,
    theme.palette.success.main,
    theme.palette.warning.main,
  ]);

  const descricao = SituacaoMock.filter((x) => x.Key === model.situacao)[0]
    .Value;

  return (
    <>
      <DefaultCard>
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        {isMobile ? (
          <Grid
            container
            spacing={2}
            style={{
              padding: 8,
            }}
          >
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="caption">
                Nº {model.nossoNumero}
              </Typography>
              <Typography color="textPrimary" variant="body2">
                <b>{model.pagadorNome}</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textPrimary" variant="caption">
                CPF/CNPJ
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {formatarCPFCNPJ(model.pagadorCpfCnpj)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textPrimary" variant="caption">
                Ano/Mês
              </Typography>
              <Typography color="textPrimary" variant="caption">
                Ano/Mês
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {model.numeroLicencas
                  ? `${model.periodoAnoMes} (${model.numeroLicencas} Licenças)`
                  : model.competenciaAnoMes}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textPrimary" variant="caption">
                Valor Custo
              </Typography>
              <Typography color="textPrimary" variant="body2">
                <b>
                  {model.valorCusto
                    ? toCurrency(model.valorCusto)
                    : toCurrency(0)}
                </b>
              </Typography>
            </Grid>
            {esconderCheckbox && (
              <Grid item xs={6}>
                <div>
                  <Tooltip
                    arrow
                    title={
                      desabilitarCheckbox
                        ? 'Antecipação Desabilitada'
                        : 'Antecipar / Transformar Fatura'
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={Boolean(
                            selected.find((x) => x.id === model.id),
                          )}
                          disabled={desabilitarCheckbox}
                        />
                      }
                      value={selected.includes(model)}
                      onChange={() => {
                        setSelected((prev) => {
                          const lista = [...prev];
                          const grupo = lista.find(
                            (x) => x.nome === model.sistemaNome,
                          );
                          const index = lista.indexOf(grupo!);

                          const fatura = lista[index].selected.find(
                            (x) => x.id === model.id,
                          );

                          if (fatura) {
                            const indexSelected =
                              lista[index].selected.indexOf(fatura);

                            lista[index].selected = lista[
                              index
                            ].selected.filter((x) => {
                              return (
                                x.id !== lista[index].selected[indexSelected].id
                              );
                            });
                            return lista;
                          }

                          lista[index].selected.push(model);

                          return lista;
                        });
                      }}
                      label="Selecionar"
                    />
                  </Tooltip>
                </div>
              </Grid>
            )}
          </Grid>
        ) : (
          <div className={`${classes.cardContent} ${listClasses.container}`}>
            <div
              className={`${classes.celulaGridFull} ${listClasses.item}`}
              style={{ minWidth: 300, maxWidth: 300 }}
            >
              <Typography color="textPrimary" variant="caption">
                Nº {model.nossoNumero}
              </Typography>
              <Typography color="textPrimary" variant="body2">
                <b>{model.pagadorNome}</b>
              </Typography>
            </div>
            <div
              className={`${classes.celulaGrid} ${listClasses.item}`}
              style={{ marginRight: 10 }}
            >
              <Typography color="textPrimary" variant="caption">
                CPF/CNPJ
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {formatarCPFCNPJ(model.pagadorCpfCnpj)}
              </Typography>
            </div>
            <div
              className={`${classes.celulaGrid} ${listClasses.item}`}
              style={{ minWidth: 200, maxWidth: 200 }}
            >
              <Typography color="textPrimary" variant="caption">
                Ano/Mês
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {model.numeroLicencas
                  ? `${model.periodoAnoMes} (${model.numeroLicencas} Licenças)`
                  : model.competenciaAnoMes}
              </Typography>
            </div>
            <div>
              <Typography color="textPrimary" variant="caption">
                Valor Custo
              </Typography>
              <Typography color="textPrimary" variant="body2">
                <b>
                  {model.valorCusto
                    ? toCurrency(model.valorCusto)
                    : toCurrency(0)}
                </b>
              </Typography>
            </div>
            {esconderCheckbox && (
              <div>
                <Tooltip
                  arrow
                  title={
                    desabilitarCheckbox
                      ? 'Antecipação Desabilitada'
                      : 'Antecipar / Transformar Fatura'
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={Boolean(
                          selected.find((x) => x.id === model.id),
                        )}
                        disabled={desabilitarCheckbox}
                      />
                    }
                    value={selected.includes(model)}
                    onChange={() => {
                      setSelected((prev) => {
                        const lista = [...prev];
                        const grupo = lista.find(
                          (x) => x.nome === model.sistemaNome,
                        );
                        const index = lista.indexOf(grupo!);

                        const fatura = lista[index].selected.find(
                          (x) => x.id === model.id,
                        );

                        if (fatura) {
                          const indexSelected =
                            lista[index].selected.indexOf(fatura);

                          lista[index].selected = lista[index].selected.filter(
                            (x) => {
                              return (
                                x.id !== lista[index].selected[indexSelected].id
                              );
                            },
                          );
                          return lista;
                        }

                        lista[index].selected.push(model);

                        return lista;
                      });
                    }}
                    label=""
                  />
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </DefaultCard>
    </>
  );
};
