import { useEffect, useState, useCallback, useMemo } from "react";
import { Box, Grid } from "@material-ui/core";
import { useStyles } from "./funcionarios-list-styles";
import { Paginacao } from "views/components/paginacao";
import { CircularLoading } from "views/components/utils/circular-loading/circular-loading";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { PessoasModel } from "model/api/gestao/pessoa/pessoas-model";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { useHistory, useLocation } from "react-router-dom";
import { ButtonFab } from "views/components/controles";
import { MenuVerticalIcon, NovoIcon } from "views/components/icons";
import { ApiListModel } from "model/api/gestao/api-list-model/api-list-model";
import { FuncionariosListSearchProps } from "./funcionarios-list-search-props";
import { useGetPessoaTipo } from "data/api/gestao/pessoa/get-pessoa-tipo";
import { useGetPessoaExportarExcel } from "data/api/gestao/pessoa/get-pessoa-exportar-excel";
import { isEmpty } from "lodash";
import { useThemeQueries } from "views/theme";
import { ButtonFabMenu } from "views/components/controles/buttons/button-fab-menu";
import { ExportarExcelIcon } from "views/components/icons/exportar-excel-icon";
import { ButtonFabMenuModel } from "model/app/components/button-fab-menu/button-fab-menu";
import { useDefaultCadastroStyles } from "views/pages/private/cadastros/components/default-cadastro-styles";
import { FuncionarioListData } from "./funcionarios-list-data";

export interface FuncionariosListProps {
  searchProps: FuncionariosListSearchProps;
}

export const FuncionariosList = (props: FuncionariosListProps) => {
  const classes = useStyles();
  const listClasses = useDefaultCadastroStyles();

  const { getPessoaTipo, carregando: carregandoGet } = useGetPessoaTipo();
  const { getPessoaExportarExcel, carregando: carregandoExcel } = useGetPessoaExportarExcel();

  const { showToast } = useToastSaurus();
  const history = useHistory()
  const location = useLocation()
  const { abrirCadastroFuncionarios, cadastroFuncionarioState } = useCadastros()
  const { tipoUsuario } = useSessaoAtual()
  const { isMobile } = useThemeQueries();

  const [queryStatus, setQueryStatus] = useState<ApiListModel<PessoasModel>>({
    pageIndex: 1,
    totalPages: 0,
    totalResults: 0,
    pageSize: 0,
    list: Array<PessoasModel>(),
  });

  const [selectedList, setSelectedList] = useState<Array<string>>([]);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setOpenMenu(true);
  }, [])

  const handleClose = useCallback(() => {
    setOpenMenu(false);
  }, [])

  const carregando = carregandoExcel || carregandoGet;

  const fillResult = useCallback(
    async (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<PessoasModel>
    ) => {
      setQueryStatus({
        pageIndex: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
        pageSize: 0
      });
    },
    []
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const query = `tiposPessoas=${EnumTipoPessoas.Funcionario}&tiposPessoas=${EnumTipoPessoas.FuncionarioFinanceiro}&query=${props.searchProps.termo}&pageSize=30`
        const res = await getPessoaTipo(newPage, query);
        if (res.erro) throw res.erro;

        if (res.resultado?.data === undefined) {
          return
        }
        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data.pageIndex > res.resultado?.data.totalPages &&
          res.resultado?.data.totalResults > 0
        ) {
          search(res.resultado.data.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data.pageIndex,
          res.resultado?.data.totalPages,
          res.resultado?.data.totalResults,
          res.resultado.data.list
        );

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [props.searchProps.termo, getPessoaTipo, fillResult, showToast]
  );

  const pageChanged = useCallback(
    async (newPage: number) => {
      if (newPage <= queryStatus.totalPages || newPage > 0) {
        search(newPage);
      }
    },
    [search, queryStatus.totalPages]
  );

  const onCardSelected = (id: string) => {
    history.push({
      pathname: `/funcionarios/${id}`,
      state: {
        from: {
          pathname: history.location.pathname,
          page: queryStatus.pageIndex
        }
      }
    });
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  useEffect(() => {
    let page = Number(new URLSearchParams(location.search).get('page'));
    if (!cadastroFuncionarioState.aberto) {
      search(page || queryStatus.pageIndex);
    }
    location.search = ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, cadastroFuncionarioState]);

  const exportExcel = useCallback(async (newPage: number) => {
    const query =
      (!isEmpty(props.searchProps.termo)
        ? '&query=' + props.searchProps.termo
        : '') + `&tipoPessoa=${EnumTipoPessoas.Funcionario}`

    try {
      const res = await getPessoaExportarExcel(newPage, query)
      if (res.erro) throw (res.erro)
      if (!res.resultado) return;

      const blob = new Blob([res.resultado?.data], { type: 'application/vnd.ms-excel' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a')

      anchor.href = url
      anchor.download = `Funcionarios-${new Date().toLocaleDateString().replaceAll('/', '-')}.xls`
      anchor.click()

    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getPessoaExportarExcel, props.searchProps.termo, showToast])

  const onClickMenuItem = useMemo(() => {
    const itens = new Array<ButtonFabMenuModel>();

    itens.push(new ButtonFabMenuModel(<ExportarExcelIcon tipo='BUTTON_FAB' />, 'Exportar Excel', () => exportExcel(1)))

    return itens;
  }, [exportExcel])

  return (
    <>
      <div className={classes.defaultContainer}>
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' flex={1} overflow='auto'>
          <Grid container className={listClasses.listContainer}>
            <Grid item xs={12}>
              <Paginacao
                pageChanged={pageChanged}
                totalPages={queryStatus.totalPages}
                totalRegisters={queryStatus.totalResults}
                currentPage={queryStatus.pageIndex}
                exportarExcel={() => exportExcel(1)}
              />
            </Grid>
            <Grid item xs={12} className={classes.listContainer}>
              <FuncionarioListData
                carregando={carregando}
                list={queryStatus.list}
                selectedList={selectedList}
                onCardSelected={onCardSelected}
                onCardChecked={onCardChecked}
                label='Funcionário'
                tooltipMessage="Editar Funcionário"
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      {((tipoUsuario() === EnumTipoPessoas.SoftwareHouse) || (tipoUsuario() === EnumTipoPessoas.Revendedor)) &&
        (
          <>
            {isMobile ? (
              <>
                {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
                <ButtonFabMenu
                  open={openMenu}
                  onOpen={handleOpen}
                  onClose={handleClose}
                  values={onClickMenuItem}
                  icon={<MenuVerticalIcon tipo='BUTTON_FAB' />}
                />
              </>
            ) : (
              <ButtonFab
                tooltip="Novo Funcionário"
                icon={<NovoIcon tipo="BUTTON_FAB" />}
                onClick={() => abrirCadastroFuncionarios('', false)}
              />
            )}
          </>
        )
      }
    </>
  );
};
