import {
  IdentidadeAutenticarProps,
  usePostIdentidadeAutenticar,
} from 'data/api/gestao/identidade';
import React, { useState } from 'react';
import { TokenGestaoModel } from '../../../../model/api/gestao/master';
import {
  useGestaoToken,
} from '../../use-cases';
import { useCallback } from 'react';
import { ApiError } from '../../../../model/app/errors/api-erros';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { EnumTipoCobrancaRevenda } from 'model/enums/enum-tipo-cobranca-revenda';
import { IdentidadeIntegracaoProps, usePostIdentidadeIntegracao } from 'data/api/gestao/identidade/post-integracao';
import { isEmpty } from 'lodash';

interface SessaoAtualContextValue {
  usuario?: TokenGestaoModel;
  carregando: boolean;
  logar: (
    usuario: string,
    senha: string,
  ) => Promise<void>;
  logarIntegracao: (
    id: string,
    documento: string,
  ) => Promise<void>;
  deslogar: () => void;
  setarUser: (token: string) => Promise<void>;
  converterToken: (token: string) => TokenGestaoModel | undefined;
  tipoUsuario: () => EnumTipoPessoas,
  persistirTermos: (status: boolean, statusCode: number) => void;
  termosDeUsoAtivo: () => boolean;
}

const SessaoAtualContext = React.createContext<SessaoAtualContextValue>({
  usuario: undefined,
  carregando: false,

  logar: (usuario: string, senha: string) => {
    return new Promise<void>(() => true);
  },
  logarIntegracao: (id: string, documento: string) => {
    return new Promise<void>(() => true);
  },
  converterToken: (token: string) => {
    return undefined;
  },
  setarUser: async () => { },
  deslogar: () => { },
  tipoUsuario: () => EnumTipoPessoas.Revendedor,
  persistirTermos: (status: boolean, statusCode: number) => { },
  termosDeUsoAtivo: () => false
});

export interface SessaoAtualProviderProps {
  children: React.ReactNode;
}

export const useSessaoAtual = () => {
  return React.useContext(SessaoAtualContext);
};

export const SessaoAtualProvider = ({ children }: SessaoAtualProviderProps) => {
  // PROVIDERS
  const {
    getTokenFromStorage,
    convertToken,
    isTokenValid,
    persistToken,
    getTermosDeUso,
    persistTermosDeUso
  } = useGestaoToken();

  const setarUsuarioEConverterEnum = useCallback(() => {
    const user = getTokenFromStorage()
    if (!user) return

    const tipoCobrancaRevenda = Number(EnumTipoCobrancaRevenda[user?.TipoCobrancaRevenda!])
    if (user) user.TipoCobrancaRevenda = tipoCobrancaRevenda

    return user
  }, [getTokenFromStorage])

  // STATES E REFS
  const [loadingManual, setLoadingManual] = useState(false);

  const [usuario, setarUsuario] = React.useState(setarUsuarioEConverterEnum());

  // CHAMDAS API
  const { postIdentidadeAutenticar, carregando: carregandoApiAutenticar } =
    usePostIdentidadeAutenticar();

  const { postIdentidadeIntegracao, carregando: carregandoApiIntegrcao } = usePostIdentidadeIntegracao()

  // AUX
  const loading =
    carregandoApiAutenticar ||
    carregandoApiIntegrcao ||
    loadingManual

  const converterToken = React.useCallback(
    (token: string): TokenGestaoModel | undefined => {
      return convertToken(token);
    },
    [convertToken],
  );

  const limparVariaveis = useCallback(() => {

    sessionStorage.clear();
  }, []);

  const deslogar = React.useCallback(
    () => {
      persistToken(undefined);
      setarUsuario(undefined);
      limparVariaveis();
    },
    [persistToken, limparVariaveis],
  );

  const setarUser = useCallback(
    async (token: string) => {
      const user = convertToken(token);
      const tipoCobrancaRevenda = Number(EnumTipoCobrancaRevenda[user?.TipoCobrancaRevenda!])
      if (user) user.TipoCobrancaRevenda = tipoCobrancaRevenda

      const tiposSemPermissao = [
        'cliente',
        'agenteexterno',
        'prospeccao'
      ]

      if(tiposSemPermissao.includes(user!.Tipo.toLowerCase())){
        throw new Error('Acesso negado.')
      }

      if (!isTokenValid(user))
        throw new Error(
          'O usuário informado não é válido ou expirou seu acesso. (InvalidToken)',
        );

      // limpando dados do storage
      limparVariaveis();
      //SALVO NO STORAGE
      persistToken(token);
      //SETAMOS O USUARIO
      setarUsuario(user);


    },
    [convertToken, isTokenValid, limparVariaveis, persistToken],
  );

  const logar = React.useCallback(
    async (
      usuario: string,
      senha: string,
    ): Promise<void> => {
      try {
        setLoadingManual(true);
        const res = await postIdentidadeAutenticar(
          new IdentidadeAutenticarProps(usuario, senha),
        );

        //USUARIO NAO CONFIRMADO ESTÁ SEM AUTORIZACAO
        if (res.statusCode === 401) {
          throw new ApiError(res.statusCode, res.erro.message);
        }

        if (res.erro) {
          throw res.erro;
        }
        await setarUser(res.resultado?.data?.accessToken);
      } catch (e) {
        throw e;
      } finally {
        setLoadingManual(false);
      }
    },
    [postIdentidadeAutenticar, setarUser],
  );

  const logarIntegracao = React.useCallback(
    async (
      id: string,
      documento: string,
    ): Promise<void> => {
      try {
        setLoadingManual(true);
        const res = await postIdentidadeIntegracao(
          new IdentidadeIntegracaoProps(id, documento),
        );

        //USUARIO NAO CONFIRMADO ESTÁ SEM AUTORIZACAO
        if (res.statusCode === 401) {
          throw new ApiError(res.statusCode, res.erro.message);
        }

        if (res.erro) {
          throw res.erro;
        }
        await setarUser(res.resultado?.data?.accessToken);
      } catch (e) {
        throw e;
      } finally {
        setLoadingManual(false);
      }
    },
    [postIdentidadeIntegracao, setarUser],
  );

  const tipoUsuario = useCallback(() => {
    switch (usuario?.Tipo?.toLowerCase()) {
      case "revenda":
        return EnumTipoPessoas.Revendedor
      case 'softwarehouse':
        return EnumTipoPessoas.SoftwareHouse
      case 'cliente':
        return EnumTipoPessoas.Cliente
      case 'funcionario':
        if(isEmpty(usuario.RevendaId)){
          return EnumTipoPessoas.FuncionarioSoftwareHouse
        }
        return EnumTipoPessoas.Funcionario
      case 'funcionariofinanceiro':
        if(isEmpty(usuario.RevendaId)){
          return EnumTipoPessoas.FinanceiroSoftwareHouse
        }
        return EnumTipoPessoas.FuncionarioFinanceiro
      case 'representante':
        return EnumTipoPessoas.Representante
      default:
        return EnumTipoPessoas.GerenteComercial
    }
  }, [usuario])

  const persistirTermos = useCallback((status: boolean, statusCode: number) => {
    persistTermosDeUso(status, statusCode)
  }, [persistTermosDeUso])

  const termosDeUsoAtivo = useCallback(() => {
    return getTermosDeUso();
  }, [getTermosDeUso])

  return (
    <SessaoAtualContext.Provider
      value={{
        usuario,
        carregando: loading,
        converterToken,
        deslogar,
        logar,
        setarUser,
        tipoUsuario,
        persistirTermos,
        termosDeUsoAtivo,
        logarIntegracao
      }}
    >
      {children}
    </SessaoAtualContext.Provider>
  );
};
