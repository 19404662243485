import React, { useCallback, useEffect, useState } from 'react'
import { FaturaFilhaModel } from 'model/api/gestao/fatura/faturas-filhas-model';
import { useThemeQueries } from 'views/theme';
import { EnumSituacao } from 'model/enums/enum-situacao';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './faturas-filhas-contador-styles';

interface FaturaContador {
    abertas: number;
    fechadas: number;
    emPagamento: number;
    paga: number;
    cancelada: number;
    atraso: number;
    bonificada: number;
}

type Props = {
    faturas: FaturaFilhaModel[]
}

export default function FaturasFilhasContador({ faturas }: Props) {
    console.log(faturas[0])

    const { theme, isMobile } = useThemeQueries();
    const classes = useStyles()

    const [contagem, setContagem] = useState<FaturaContador>({
        abertas: 0,
        atraso: 0,
        bonificada: 0,
        cancelada: 0,
        emPagamento: 0,
        fechadas: 0,
        paga: 0,
    })

    const contarSituacoes = useCallback(() => {
        let abertas = 0;
        let atraso = 0;
        let bonificada = 0;
        let cancelada = 0;
        let emPagamento = 0;
        let fechadas = 0;
        let paga = 0;

        faturas.forEach(fatura => {
            switch (fatura.situacao) {
                case EnumSituacao.Aberta:
                    abertas += 1;
                    break;
                case EnumSituacao.Atraso:
                    atraso += 1;
                    break;
                case EnumSituacao.Bonificada:
                    bonificada += 1;
                    break;
                case EnumSituacao.Cancelada:
                    cancelada += 1;
                    break;
                case EnumSituacao.EmPagamento:
                    emPagamento += 1;
                    break;
                case EnumSituacao.Fechada:
                    fechadas += 1;
                    break;
                case EnumSituacao.Paga:
                    paga += 1;
                    break;
            }
        })
        setContagem({
            abertas,
            atraso,
            bonificada,
            cancelada,
            emPagamento,
            fechadas,
            paga
        })
    }, [faturas]);

    const retornarCorStatus = useCallback((situacao: EnumSituacao): string => {
        switch (situacao) {
            case EnumSituacao.Aberta:
                return theme.palette.info.main
            case EnumSituacao.Paga:
                return theme.palette.success.main
            case EnumSituacao.Atraso:
                return theme.palette.warning.main
            case EnumSituacao.Bonificada:
                return '#D7096C'
            case EnumSituacao.Cancelada:
                return theme.palette.error.main
            case EnumSituacao.Fechada:
                return '#8346a6'
        }
        return theme.palette.info.main
    }, [theme]
    )

    useEffect(() => {
        contarSituacoes()
    }, [contarSituacoes])


    const valorCustoTotal = [...faturas].reduce((prev, curr) =>  curr.situacao === EnumSituacao.Bonificada ? prev : curr.valorCusto + prev, 0)

    return (
        <Box borderTop={`1px solid ${theme.palette.divider}`} p={isMobile ? '10px 15px' : '10px 24px'}>
            <Typography variant='caption' color='textSecondary'>Registro(s) Encontrado(s):</Typography>
            <Box display='flex' gridGap={10} flexWrap='wrap' justifyContent='space-between'>
                {(contagem.abertas > 0) && (
                    <Box display='flex' alignItems='center' gridGap={7}>
                        <Box className={classes.colorBox} style={{ background: retornarCorStatus(EnumSituacao.Aberta) }} />
                        {contagem.abertas} Aberta{contagem.abertas > 1 ? 's' : ''}
                    </Box>
                )}
                {(contagem.atraso > 0) && (
                    <Box display='flex' alignItems='center' gridGap={7}>
                        <Box className={classes.colorBox} style={{ background: retornarCorStatus(EnumSituacao.Atraso) }} />
                        {contagem.atraso} Atrasada{contagem.atraso > 1 ? 's' : ''}
                    </Box>
                )}
                {(contagem.bonificada > 0) && (
                    <Box display='flex' alignItems='center' gridGap={7}>
                        <Box className={classes.colorBox} style={{ background: retornarCorStatus(EnumSituacao.Bonificada) }} />
                        {contagem.bonificada} Bonificada{contagem.bonificada > 1 ? 's' : ''}
                    </Box>
                )}
                {(contagem.cancelada > 0) && (
                    <Box display='flex' alignItems='center' gridGap={7} >
                        <Box className={classes.colorBox} style={{ background: retornarCorStatus(EnumSituacao.Cancelada) }} />
                        {contagem.cancelada} Cancelada{contagem.cancelada > 1 ? 's' : ''}
                    </Box>
                )}
                {(contagem.emPagamento > 0) && (
                    <Box display='flex' alignItems='center' gridGap={7}>
                        <Box className={classes.colorBox} style={{ background: retornarCorStatus(EnumSituacao.EmPagamento) }} />
                        {contagem.emPagamento} Em Pagamento
                    </Box>
                )}
                {(contagem.fechadas > 0) && (
                    <Box display='flex' alignItems='center' gridGap={7}>
                        <Box className={classes.colorBox} style={{ background: retornarCorStatus(EnumSituacao.Fechada) }} />
                        {contagem.fechadas} Fechada{contagem.fechadas > 1 ? 's' : ''}
                    </Box>
                )}
                {(contagem.paga > 0) && (
                    <Box display='flex' alignItems='center' gridGap={7}>
                        <Box className={classes.colorBox} style={{ background: retornarCorStatus(EnumSituacao.Paga) }} />
                        {contagem.paga} Paga{contagem.paga > 1 ? 's' : ''}
                    </Box>
                )}

                <Box display='flex' gridGap={30}>
                    <Box display='flex' flexDirection='column' alignItems='flex-end'>
                        <Typography variant='caption' color='textSecondary'>Valor Custo Total:</Typography>
                        <Typography color='textSecondary' style={{ fontSize: 14 }}> <b>{valorCustoTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b></Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}