import { PessoasModel } from "model/api/gestao/pessoa/pessoas-model";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { useCallback } from "react";
import { useSessaoAtual } from "services/app";
import { CardNaoEncontrado } from "views/components/cards";
import { CardClientes } from "views/components/cards/card-clientes";
import { InformacaoIcon } from "views/components/icons";

export interface LabelRevendaRepresentanteCliente {
  value: 'revendaNome' | 'vendedorNome'
}

export interface ClientesGridProps {
  list: Array<PessoasModel>;
  carregando: boolean;
  selectedList: Array<string>;
  onCardSelected: (id: string) => any;
  onCardChecked: (id: string) => any;
  label: string
  tooltipMessage?: string,
  emptyMessage?: string,
}

export const ClientesListData = (props: ClientesGridProps) => {

  const { tipoUsuario } = useSessaoAtual();

  const onCardSelected = (id: string) => {
    props.onCardSelected(id);
  };

  const onCardChecked = (id: string) => {
    props.onCardChecked(id);
  };

  const retRepresentante = useCallback((): LabelRevendaRepresentanteCliente => {
    if (tipoUsuario() === EnumTipoPessoas.SoftwareHouse ||
      tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse ||
      tipoUsuario() === EnumTipoPessoas.FuncionarioSoftwareHouse ||
      tipoUsuario() === EnumTipoPessoas.GerenteComercial ||
      tipoUsuario() === EnumTipoPessoas.Representante) {
      return {
        value: 'revendaNome'
      }
    }
    return {
      value: 'vendedorNome'

    }
  }, [tipoUsuario])

  return (
    <>
      {props.list.length === 0 && !props.carregando && (
        <CardNaoEncontrado
          mensagem={props.emptyMessage || `Nenhum ${props.label} Encontrado.`}
          icon={<InformacaoIcon tipo="GERAL" />}
        />
      )}
      {props?.list.length > 0 &&
        props.list.map((clientes, index) => {
          return (
            <CardClientes
              selected={
                props.selectedList.filter((item) => item === clientes.id).length >
                  0
                  ? true
                  : false
              }
              onCheck={onCardChecked}
              onClick={onCardSelected}
              retRepresentante={retRepresentante()}
              model={clientes}
              tooltipMessage={props.tooltipMessage}
              key={index}
            />
          );
        })}
    </>
  );
};
