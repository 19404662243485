import { Box, Button, Grid, Grow } from "@material-ui/core";
import { useGetContratos } from "data/api/gestao/contratos";
import { isEmpty } from "lodash";
import { ContratoModel } from "model/api/gestao/contrato/contrato-model";
import { PessoaPostModel } from "model/api/gestao/pessoa/pessoa-post-model";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { CircularLoading } from "views/components";
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { NovoIcon } from "views/components/icons";
import { DialogFuncionarioContrato } from "views/components/dialog/dialog-funcionario-contrato/dialog-funcionario-contrato";
import { useThemeQueries } from "views/theme";
import { AccordionClienteContratosListData } from "./accordion-cliente-contratos-list-data";
import { EnumTipoCobrancaRevenda } from "model/enums/enum-tipo-cobranca-revenda";

type Props = {
  pessoaId: string;
  cliente: PessoaPostModel;
  revendaId: string | null;
}

export const AccordionClienteContratosList = ({ pessoaId, revendaId, cliente }: Props) => {
  const { theme } = useThemeQueries()
  const [openAccordion, setOpenAccordion] = useState<boolean>(false)

  const [query, setQuery] = useState<string>('')

  const { tipoUsuario } = useSessaoAtual()
  const { abrirCadastroContrato, cadastroContratoState } = useCadastros()

  const { location } = useHistory()

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<ContratoModel>(),
  });

  const [selectedId, setSelectedId] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const { showToast } = useToastSaurus()
  const { getContratos, carregando } = useGetContratos()

  const isFuncionario = tipoUsuario() === EnumTipoPessoas.Funcionario || tipoUsuario() === EnumTipoPessoas.FuncionarioSoftwareHouse

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<ContratoModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages,
      });
    },
    []
  );

  const search = useCallback(
    async (newPage: number) => {
      try {
        const searchQuery = !isEmpty(query) ? `query=${query}` : ''
        const res = await getContratos(pessoaId, newPage, `pageSize=0&${searchQuery}`,);
        if (res.erro) throw res.erro;

        if (!res.resultado) {
          return;
        }

        //se o index for maior que as paginas ele busca a ultima
        if (
          res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
          res.resultado?.data?.totalResults > 0
        ) {
          await search(res.resultado?.data?.totalPages);
          return;
        }

        fillResult(
          res.resultado?.data?.pageIndex,
          res.resultado?.data?.totalPages,
          res.resultado?.data?.totalResults,
          res.resultado?.data?.list
        );

        return res;
      } catch (e: any) {
        showToast("error", e.message);
      }
    },
    [query, getContratos, pessoaId, fillResult, showToast]
  );

  useEffect(() => {
    if (openAccordion && !cadastroContratoState.aberto) {
      search(queryStatus.page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cadastroContratoState.aberto, openAccordion, queryStatus.page, showToast])

  const onCardSelected = useCallback((id: string) => {
    if (isFuncionario) {
      setSelectedId(id)
      setOpenDialog(true)
      return
    }
    abrirCadastroContrato(id, pessoaId, location.pathname, true, !isEmpty(cliente.vendedorId), revendaId || undefined)
  }, [isFuncionario, abrirCadastroContrato, pessoaId, location.pathname, cliente.vendedorId, revendaId])

  return (
    <>
      <AccordionSaurus
        labelPrimary="Contratos"
        tipoExpand="bold"
        noPaperRoot={false}
        heightDivider={2}
        showDivider={openAccordion}
        colorDivider={theme.palette.primary.main}
        colorExpand={theme.palette.primary.main}
        expanded={openAccordion}
        labelSecondary={openAccordion &&
          <Grow in>
            <Box display={"flex"} width={"100%"} justifyContent={"flex-end"} ml={2}>
              <TextFieldSaurus
                style={{ marginBottom: 10 }}
                placeholder='Buscar Contrato'
                size='small'
                allowSubmit={true}
                value={query}
                fullWidth={false}
                searchable
                onClick={() => setOpenAccordion(prev => {
                  if (!prev) return true

                  return !prev
                })}
                onSearch={() => {
                  search(1)
                }}
                onChange={(e: any) => setQuery(e.target.value)}
              />
            </Box>
          </Grow>
        }
        onChange={() => setOpenAccordion(prev => !prev)}
      >
        <Grid container spacing={1} style={{
          position: 'relative'
        }}>
          {carregando && <CircularLoading tipo="FULLSIZED" />}

          <Grid item xs={12}>
            <AccordionClienteContratosListData
              list={queryStatus.list}
              carregando={carregando}
              onCardSelected={onCardSelected}
              onCardChecked={() => { }}
              selectedList={['daoisjdo']}
              isTipoRevenda={cliente.revendaTipoCobranca !== EnumTipoCobrancaRevenda.ClienteFinal}
            />
          </Grid>
          {(tipoUsuario() === EnumTipoPessoas.Revendedor || tipoUsuario() === EnumTipoPessoas.FuncionarioFinanceiro ||
            tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.Representante) && <Grid item xs={12} style={{ marginBottom: 8 }}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} md={4}>
                  <Button variant="contained" color='primary' fullWidth
                    onClick={() => {
                      abrirCadastroContrato('', pessoaId, location.pathname, true, !isEmpty(cliente.vendedorId), revendaId || undefined)
                    }}>
                    <NovoIcon tipo='BUTTON_PRIMARY' /> NOVO CONTRATO
                  </Button>
                </Grid>
              </Grid>
            </Grid>}
        </Grid>
      </AccordionSaurus>
      {(isFuncionario && openDialog) && <DialogFuncionarioContrato
        id={selectedId}
        pessoaId={pessoaId}
        closeDialog={() => setOpenDialog(false)}
        openned={openDialog}
      />}
    </>
  );
};
