export enum EnumTipoPessoas {
    SoftwareHouse = 0,
    Revendedor = 1,
    Cliente = 2,
    Funcionario = 3,
    GerenteComercial = 4,
    Prospeccao = 5,
    AgenteExterno = 6,
    Representante = 7,
    FuncionarioFinanceiro = 8,
    FinanceiroSoftwareHouse = 20,
    FuncionarioSoftwareHouse = 21,
}