import { Box, Button, Divider, Grid, Tooltip, Typography } from "@material-ui/core"
import { useCallback, useEffect, useRef, useState } from "react"
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { useThemeQueries } from "views/theme"
import { FaturaModel } from "model/api/gestao/fatura/fatura-model"
import { DefaultFormRefs } from "views/components/form/utils"
import { NotaFiscalModel } from "model/api/gestao/nota-fiscal/nota-fiscal-model"
import { useSessaoAtual, useToastSaurus } from "services/app"
import { useGetNotaFiscal } from "data/api/gestao/nota-fiscal/get-nota-fiscal"
import { NotaFiscalFormModel } from "model/api/gestao/nota-fiscal/emitir-nota-fiscal-model"
import { FormNotaFiscalEditar } from "views/components/form/master/nota-fiscal/nota-fiscal-editar/form-nota-fiscal-editar"
import { useStyles } from './accordion-nota-fiscal-styles'
import { CancelarIcon, DesfazerIcon, EditarIcon, NotaFiscalIcon, SalvarEditarIcon } from "views/components/icons"
import { usePutNotaFiscal } from "data/api/gestao/nota-fiscal/put-nota-fiscal"
import { usePostEmitirNotaFiscal } from "data/api/gestao/nota-fiscal/post-emitir-nota-fiscal"
import { EnumSituacaoNotaFiscal } from "model/enums/enum-situacao-nota-fiscal"
import { usePostCancelarNotaFiscal } from "data/api/gestao/nota-fiscal/post-cancelar-nota-fiscal"
import NotaFiscalTributos from "./components/nota-fiscal-tributos"
import { useConfirm } from "material-ui-confirm"
import { useDefaultCardStyles } from "views/components/cards/components"
import { useGetNotaFiscalDanfe } from "data/api/gestao/nota-fiscal/get-nota-fiscal-danfe"
import { CircularLoading } from "views/components"
import { EnviarEmailIcon } from "views/components/icons/enviar-email"
import { DialogEnviarEmail } from "views/components/dialog/dialog-enviar-email/dialog-enviar-email"
import { EnviarEmail } from "model/api/gestao/nota-fiscal/nota-fiscal-enviar-email-danfe"
import { usePostNotaFiscal } from "data/api/gestao/nota-fiscal/post-nota-fiscal"
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas"

interface Props {
    model: FaturaModel
}

export const AccordionNotaFiscal = ({ model }: Props) => {

    const { theme, isMobile } = useThemeQueries()
    const { showToast } = useToastSaurus()
    const classes = useStyles();
    const cardClasses = useDefaultCardStyles();
    const confirm = useConfirm()
    const { tipoUsuario } = useSessaoAtual()

    const { getNotaFiscal, carregando: carregandoGet } = useGetNotaFiscal()
    const { putNotaFiscal, carregando: carregandoPut } = usePutNotaFiscal()
    const { postEmitirNotaFiscal, carregando: carregandoEmitir } = usePostEmitirNotaFiscal()
    const { postNotaFiscal, carregando: carregandoPost } = usePostNotaFiscal()
    const { postCancelarNotaFiscal, carregando: carregandoCancelar } = usePostCancelarNotaFiscal()
    const { getNotaFiscalDanfe, carregando: carregandoGetDanfe } = useGetNotaFiscalDanfe()
    const [aberto, setAberto] = useState(false);

    const [openAccordion, setOpenAccordion] = useState<boolean>(false)

    const [notaFiscal, setNotaFiscal] = useState<NotaFiscalModel>(new NotaFiscalModel())
    const [notaFiscalForm, setNotaFiscalForm] = useState<NotaFiscalFormModel>(new NotaFiscalFormModel())

    const [editar, setEditar] = useState<boolean>(false)
    const [criarNF, setCriarNF] = useState<boolean>(false)

    const formPutRef = useRef<DefaultFormRefs<NotaFiscalFormModel>>(null)

    const carregando = carregandoGet || carregandoPut || carregandoEmitir || carregandoCancelar || carregandoGetDanfe || carregandoPost

    const recarregarForm = useCallback((model: NotaFiscalModel) => {
        const formPut = new NotaFiscalFormModel(model.id, model.aliquotaDeducao, model.aliquotaISS,
            model.aliquotaPIS, model.aliquotaCOFINS, model.aliquotaINSS, model.aliquotaIR, model.aliquotaCSLL, model.pTotTrib,
            model.tipoTributacao, model.codigoServico, model.textoServico, model.issRetido, model.serieRPS, model.nroRPS, model.descricao);

        setNotaFiscalForm(formPut)
        formPutRef.current?.fillForm(formPut)
    }, [])

    const notaFiscalWrapper = useCallback(async () => {
        try {
            const res = await getNotaFiscal(model.id)
            // if (res.erro) throw res.erro
            if (!res.resultado || res.statusCode === 204 || res.erro) {
                setCriarNF(true)
                return
            }

            setNotaFiscal(res.resultado.data)
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getNotaFiscal, model.id, showToast])

    const handleGetDanfe = useCallback(async () => {
        try {
            const res = await getNotaFiscalDanfe(model.id)

            if (res.erro) throw res.erro
            if (!res.resultado) throw new Error('Erro ao imprimir Nota Fiscal')

            window.open(res.resultado.data.url, '_blank')
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getNotaFiscalDanfe, model.id, showToast])

    const handlePut = useCallback(async (notaFiscal: NotaFiscalFormModel) => {
        try {
            const res = await putNotaFiscal(model.id, notaFiscal)

            if (res.erro) throw res.erro
            if (!res.resultado) throw new Error('Erro ao carregar Nota Fiscal')

            showToast('success', 'Nota Fiscal atualizada')

            setNotaFiscal(res.resultado.data)
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [model.id, putNotaFiscal, showToast])

    const handleEmitir = useCallback(async () => {
        try {
            const res = await postEmitirNotaFiscal(model.id, notaFiscalForm)

            if (res.erro) throw res.erro
            if (!res.resultado) throw new Error('Erro ao carregar Nota Fiscal')
            if (res.statusCode === 204 || !res.resultado?.data) {
                notaFiscalWrapper();
                return
            }

            if (res.resultado.data.situacao !== EnumSituacaoNotaFiscal.Autorizada) {
                showToast('error', res.resultado.data.retornoAutorizacao);
            } else {
                showToast('success', res.resultado.data.retornoAutorizacao)
            }
            setNotaFiscal(res.resultado.data)
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [model.id, notaFiscalForm, notaFiscalWrapper, postEmitirNotaFiscal, showToast])

    const handleCancelar = useCallback(async () => {
        try {
            const res = await postCancelarNotaFiscal(model.id, notaFiscal.id)

            if (res.erro) throw res.erro

            if (res.statusCode === 204 || !res.resultado?.data || !res.resultado) {
                notaFiscalWrapper();
                return
            }

            setNotaFiscal(res.resultado.data)

            showToast('success', 'Nota Fiscal Cancelada')
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [model.id, notaFiscal, notaFiscalWrapper, postCancelarNotaFiscal, showToast])

    const criarNotaFiscal = useCallback(async () => {
        try {
            const res = await postNotaFiscal(model.id)

            if (res.erro) throw res.erro
            if (!res.resultado) throw new Error('Ocorreu um erro inesperado. Tente novamente. Caso o erro persista contate o desenvolvedor do sistema.')

            if (res.statusCode === 204 || !res.resultado?.data) {
                setCriarNF(false)
                notaFiscalWrapper();
                return
            }

            setCriarNF(false)
            setNotaFiscal(res.resultado.data)

            showToast('success', 'Nota Fiscal Criada')
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [model.id, notaFiscalWrapper, postNotaFiscal, showToast])

    useEffect(() => {
        recarregarForm(notaFiscal)
    }, [notaFiscal, editar, recarregarForm])

    useEffect(() => {
        if (openAccordion)
            notaFiscalWrapper()
    }, [notaFiscalWrapper, openAccordion])

    const confirmCancel = useCallback(() => {
        confirm({
            title: 'Cancelar Nota Fiscal',
            description: 'Tem certeza que deseja cancelar esta nota fiscal?',
            confirmationText: 'Confirmar',
            cancellationText: 'Voltar'
        }).then(() => handleCancelar())
    }, [confirm, handleCancelar])

    const retornarCorStatus = useCallback((): string => {
        switch (notaFiscal.situacao) {
            case EnumSituacaoNotaFiscal.Pendente:
                return theme.palette.warning.main
            case EnumSituacaoNotaFiscal.Autorizada:
                return theme.palette.success.main
            default:
                return theme.palette.error.main
        }
    }, [notaFiscal.situacao, theme.palette.error.main, theme.palette.success.main, theme.palette.warning.main])

    const retornaStatus = useCallback((): string => {
        switch (notaFiscal.situacao) {
            case EnumSituacaoNotaFiscal.Autorizada:
                return 'Autorizada';
            case EnumSituacaoNotaFiscal.Pendente:
                return 'Pendente';
            default:
                return 'Cancelada';
        }
    }, [notaFiscal.situacao])

    const podeGerarNota = (
        (notaFiscal.situacao === EnumSituacaoNotaFiscal.Autorizada ||
            notaFiscal.situacao === EnumSituacaoNotaFiscal.Cancelada) &&
        (tipoUsuario() === EnumTipoPessoas.SoftwareHouse ||
            tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse)

    )

    const podeCancelarNota = (
        tipoUsuario() === EnumTipoPessoas.SoftwareHouse ||
        tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse
    )

    return (
        <>
            <DialogEnviarEmail closeModal={() => setAberto(false)} openned={aberto} model={model} email={new EnviarEmail()} />
            <AccordionSaurus
                labelPrimary="Nota Fiscal"
                tipoExpand="bold"
                noPaperRoot={false}
                heightDivider={2}
                showDivider={openAccordion}
                colorDivider={theme.palette.primary.main}
                colorExpand={theme.palette.primary.main}
                expanded={openAccordion}
                onChange={() => setOpenAccordion(prev => !prev)}
            >
                {carregando ? (
                    <CircularLoading tipo="FULLSIZED" />
                ) : null}
                {!criarNF ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='flex-end' alignContent='center' pr={1}>
                                <Box minWidth={isMobile ? '100%' : 120} maxWidth={isMobile ? undefined : 200}>
                                    <Button
                                        className={cardClasses.tagStatus}
                                        style={{ background: retornarCorStatus(), width: '100%', color: '#FFF' }}
                                    >
                                        {retornaStatus()}
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} className={classes.fieldContainer}>
                            <Box display='flex' justifyContent='space-between' alignItems='center' pb={1}>
                                <Typography variant='h6'>Tributação</Typography>
                                {tipoUsuario() === EnumTipoPessoas.SoftwareHouse && <Tooltip arrow title={editar ? 'Voltar' : 'Editar'}>
                                    <Button onClick={() => setEditar(prev => !prev)}>
                                        {editar ? <DesfazerIcon tipo='BUTTON' /> : <EditarIcon tipo='BUTTON' />}
                                    </Button>
                                </Tooltip>}
                            </Box>
                            <Divider style={{ background: theme.palette.primary.main, marginBottom: 10 }} />
                            {!editar ?
                                (<NotaFiscalTributos model={notaFiscal} />)
                                :
                                (<FormNotaFiscalEditar
                                    loading={carregando}
                                    showLoading={carregando}
                                    onSubmit={handlePut}
                                    ref={formPutRef}
                                />)
                            }
                            {editar &&
                                (<Box display='flex' justifyContent='flex-end' mt={1}>
                                    <Button variant='contained' color='primary' fullWidth={isMobile} onClick={() => formPutRef.current?.submitForm()}>
                                        <SalvarEditarIcon tipo='BUTTON_PRIMARY' />
                                        Salvar
                                    </Button>
                                </Box>)
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Divider style={{ background: theme.palette.divider }} />
                        </Grid>
                        <Grid item xs={12} className={classes.buttons}>
                            <Grid container spacing={2} justifyContent='flex-end'>
                                {notaFiscal.situacao === EnumSituacaoNotaFiscal.Autorizada && (
                                    <>
                                        <Grid item xs={12} lg={3} className={classes.endButtons}>

                                            <Button variant='contained' color="primary" fullWidth onClick={() => {
                                                setAberto(true)
                                            }}>
                                                <EnviarEmailIcon tipo='BUTTON_PRIMARY' />
                                                Enviar para Email
                                            </Button>

                                        </Grid>
                                        {podeCancelarNota && <Grid item xs={12} lg={2} className={classes.endButtons}>

                                            <Button variant='outlined' className={classes.buttonRemover} fullWidth onClick={confirmCancel}>
                                                <CancelarIcon tipo='BUTTON' />
                                                Cancelar
                                            </Button>

                                        </Grid>}
                                    </>
                                )}
                                {podeGerarNota && (
                                    <Grid item xs={12} lg={3} className={classes.endButtons}>
                                        <Button variant='outlined' color='primary' fullWidth onClick={handleGetDanfe}>
                                            <NotaFiscalIcon tipo='BUTTON' />
                                            Gerar Nota
                                        </Button>

                                    </Grid>
                                )}
                                {(notaFiscal.situacao === EnumSituacaoNotaFiscal.Pendente && tipoUsuario() === EnumTipoPessoas.SoftwareHouse) &&
                                    (<Grid item xs={12} lg={3} className={classes.endButtons}>
                                        <Button variant='outlined' color='primary' fullWidth onClick={handleEmitir}>
                                            <NotaFiscalIcon tipo='BUTTON' />
                                            Emitir
                                        </Button>
                                    </Grid>)
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent="center" style={{ flexDirection: 'column', gap: 10 }} alignItems='center'>
                        <Typography>Deseja criar uma Nota Fiscal para esta fatura?</Typography>
                        <Button variant='contained' color='primary' onClick={criarNotaFiscal}>
                            <NotaFiscalIcon tipo='BUTTON_PRIMARY' />
                            Criar Nota Fiscal
                        </Button>
                    </Grid>
                )}
            </AccordionSaurus >
        </>
    )
}