import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Box, Grid, Button, Tooltip, Typography, Divider } from '@material-ui/core';
import { useFormPessoaEditValidation } from './form-pessoa-validation';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  ImagemInput,
  TextFieldSaurus,

} from 'views/components/controles/inputs';
import {
  DefaultFormProps,
  DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { picker } from 'utils/picker';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { PessoaPostModel } from 'model/api/gestao/pessoa/pessoa-post-model';
import ImagemPadrao from 'assets/img/usuario-sem-imagem.png'
import { validarCPFCNPJ } from 'utils/cpfcnpj-validate';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { ProcurarIcon } from 'views/components/icons';
import { stringNumeros } from 'utils/string-numeros';
import { useStyles } from './form-pessoa-edicao-styles'
import { TipoCobrancaRevendaMock } from 'data/mocks/tipo-cobranca-revenda-mock';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { SituacaoPessoaMock } from 'data/mocks/pessoa-situacao-mock';
import { AutocompleteRevendas } from 'views/components/controles/autocompletes/autocomplete-revendas/autocomplete-revendas';
import { AutocompleteGerentes } from 'views/components/controles/autocompletes/autocomplete-gerentes/autocomplete-gerentes';
import DomToImage from 'dom-to-image';
import { isEmpty } from 'lodash';
import { AutocompleteVendedor } from 'views/components/controles/autocompletes/autocomplete-vendedor/autocomplete-vendedor';
import { AutocompleteClassificacoes } from 'views/components/controles/autocompletes/autocomplete-classificacao/autocomplete-classificacao';
import { TipoAdiantamentoMock } from 'data/mocks/tipo-adiantamento-mock';
import { EnumTipoCobrancaRevenda } from 'model/enums/enum-tipo-cobranca-revenda';
import { toDateString, toDateStringApi } from 'utils/to-date';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { TipoAvaliacaoDesempenhoMock } from 'data/mocks/tipo-avaliacao-desempenho-mock';
import { useParams } from 'react-router-dom';
import { ClienteIdeal } from 'views/components/cliente-ideal/cliente-ideal';

const acessosCamposCompletos = [
  EnumTipoPessoas.SoftwareHouse,
  EnumTipoPessoas.FinanceiroSoftwareHouse,
]


interface FormPessoaEdicaoProps extends DefaultFormProps<PessoaPostModel> {
  tipoRevenda: boolean; // pessoa que está editando
  tipoFormPessoa: EnumTipoPessoas; // pessoa que está sendo editada
  handleLiberacaoProvisoria?: () => Promise<void>;
  configuracoes?: boolean;
  consultarCnpj?: () => void;
  showClienteIdeal?: boolean
}

export const FormPessoaEdicao = forwardRef<
  DefaultFormRefs<PessoaPostModel>,
  FormPessoaEdicaoProps
>(
  (
    { loading, onSubmit, tipoRevenda, tipoFormPessoa, configuracoes = false, ...props }: FormPessoaEdicaoProps,
    ref,
  ) => {
    const { isMobile } = useThemeQueries();
    const utilClasses = makeUtilClasses();
    const classes = useStyles()
    const refInputNome = useRef<HTMLInputElement>(null);
    const { FormPessoaEditValidationYup } = useFormPessoaEditValidation(tipoFormPessoa === EnumTipoPessoas.Revendedor, configuracoes, tipoFormPessoa === EnumTipoPessoas.SoftwareHouse)
    const { showToast } = useToastSaurus()
    const { tipoUsuario } = useSessaoAtual()
    const { theme } = useThemeQueries()
    const pinRef = useRef<HTMLDivElement>(null)
    const [img, setImg] = useState<string>('')

    const [loadingPin, setLoadingPin] = useState<boolean>(false)

    const showClienteIdealAdmin = acessosCamposCompletos.includes(tipoUsuario());

    // const { getPessoa, carregando: carregandoGet } = useGetPessoa()

    const [model, setModel] = useState<PessoaPostModel>(new PessoaPostModel())

    const podeLiberar = useMemo(() => (model.tipo === EnumTipoPessoas.Cliente && model.revendaTipoCobranca === EnumTipoCobrancaRevenda.ClienteFinal) ||
      (model.tipo === EnumTipoPessoas.Revendedor && (model.tipoCobrancaRevenda === EnumTipoCobrancaRevenda.Revenda || model.tipoCobrancaRevenda === EnumTipoCobrancaRevenda.RevendaPagas) &&
        (tipoUsuario() === EnumTipoPessoas.Revendedor && model.tipo === EnumTipoPessoas.Revendedor ? false : true)), [model.revendaTipoCobranca, model.tipo, model.tipoCobrancaRevenda, tipoUsuario])

    const { parametro } = useParams<{
      parametro: string
    }>()

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      reset,
      setValue,
      getValues,
    } = useForm<PessoaPostModel>({
      defaultValues: { ...model },
      resolver: yupResolver(FormPessoaEditValidationYup),
      criteriaMode: 'all',
      mode: 'onBlur' && 'onChange',
    });

    const imagemBase64 = useCallback((value: string) => {
      try {
        return window.atob(value)
      } catch (e: any) {
        return value
      }
    }, [])

    const ImagemBlobParaBase64 = (arquivo: Blob): Promise<{ base64: string, extensao: string }> => {

      return new Promise((resolve) => {

        if (!arquivo) {

          resolve({ base64: "", extensao: "" });

        }

        const reader = new FileReader();

        reader.onload = (event) => {
          const base64 = event.target?.result?.toString() || "";
          const extensao = arquivo.type.split("/")[1];
          resolve({ base64, extensao });
        };
        reader.readAsDataURL(arquivo);

      });

    }

    const createMarker = useCallback(async () => {
      try {
        const pinImg = await DomToImage.toBlob(pinRef.current!, {
          height: 70,
          width: 53
        })
        const b64 = await ImagemBlobParaBase64(pinImg)
        return b64.base64
      } catch (e: any) {
        return ''
      }
    }, [])

    const submit = async (values: PessoaPostModel) => {
      const pessoa = picker<PessoaPostModel>(values, new PessoaPostModel())
      if (!validarCPFCNPJ(pessoa.cpfCnpj)) {
        return showToast('error', 'CPF/CNPJ Inválido')
      }
      if (!isEmpty(img)) {
        setLoadingPin(true)
        pessoa.urlImagemPin = await createMarker();
        setLoadingPin(false)
      }
      if (tipoFormPessoa !== EnumTipoPessoas.Revendedor) {
        pessoa.melhorDia = null
        pessoa.status = null
      } else {
        pessoa.melhorDia = 5
      }
      if (!isEmpty(pessoa.dataLimiteLiberacao)) {
        pessoa.dataLimiteLiberacao = toDateStringApi(new Date(pessoa.dataLimiteLiberacao))
      }
      onSubmit(pessoa, model);
    };

    const carregando = loading

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(submit)();
      },
      resetForm: () => {
        reset();
      },
      fillForm: (values: PessoaPostModel) => {
        values.dataLimiteLiberacao = toDateString(values.dataLimiteLiberacao, 'yyyy-MM-DD HH:mm') ?? ''
        values.dataUltimaLiberacao = toDateString(values.dataUltimaLiberacao, 'yyyy-MM-DD HH:mm') ?? ''
        setModel(values)
        reset({ ...values })
      },
    }));

    // const quantidadeDias = () => {
    //   let dias: Array<number> = [5, 20, 25, 30];
    //   return dias.map((item) => new KeyValueModel(item, item === 30 ? `${item} (Desabilitado)` : item.toString()));
    // };

    const isFuncionario = tipoUsuario() === EnumTipoPessoas.Funcionario || tipoUsuario() === EnumTipoPessoas.FuncionarioSoftwareHouse
    const isSoftwareHouse = tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse

    return (
      <>
        <Box my={2}>
          <div className={utilClasses.formContainer} style={{
            position: 'relative'
          }}>
            {carregando && props.showLoading ? (
              <div className={utilClasses.controlLoading}>
                <CircularLoading tipo="FULLSIZED" />
              </div>
            ) : null}

            <form
              onSubmit={handleSubmit(submit)}
              className={carregando ? utilClasses.controlLoading : ''}
              style={{ padding: !isMobile ? '0 10px' : '0' }}
              autoComplete='off'
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} lg={props.showClienteIdeal && !showClienteIdealAdmin ? 8 : 12}>
                      <Grid container spacing={2}>
                        {tipoFormPessoa !== EnumTipoPessoas.Cliente && (
                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                            <Box mb={3}>
                              <Controller
                                name="urlImagem"
                                control={control}
                                render={({ field }) => (
                                  <ImagemInput
                                    loadErrorImage={ImagemPadrao}
                                    accept='image/*'
                                    imageRadius="50%"
                                    width={isMobile ? '180px' : '200px'}
                                    height={isMobile ? '180px' : '200px'}
                                    error={Boolean(
                                      errors.urlImagem && errors.urlImagem.message,
                                    )}
                                    helperText={
                                      touchedFields.urlImagem || errors.urlImagem
                                        ? errors.urlImagem?.message
                                        : undefined
                                    }
                                    {...field}
                                    value={imagemBase64(getValues('urlImagem'))}
                                    onChange={({ base64 }: any) => {
                                      setValue('urlImagem', base64);
                                      setImg(base64)
                                    }}
                                  />
                                )}
                              />
                            </Box>
                          </Grid>
                        )}

                        {(!configuracoes || (tipoUsuario() === EnumTipoPessoas.SoftwareHouse)) && (
                          <Grid item xs={12}>
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <Controller
                                name="cpfCnpj"
                                control={control}
                                render={({ field }) => (
                                  <Box pr={stringNumeros(getValues('cpfCnpj')).length === 14 ? 1 : 0} width="100%">
                                    <TextFieldSaurus
                                      disabled={!isSoftwareHouse || loading}
                                      allowSubmit={false}
                                      tipo="CNPJ_CPF"
                                      label="CPF/CNPJ"
                                      fullWidth
                                      autoComplete={'off'}
                                      helperText={
                                        errors.cpfCnpj
                                          ? errors.cpfCnpj.message
                                          : undefined
                                      }
                                      error={Boolean(errors.cpfCnpj && errors.cpfCnpj.message)}
                                      {...field}
                                    />
                                  </Box>
                                )}
                              />
                              {(stringNumeros(getValues('cpfCnpj')).length === 14 && tipoUsuario() !== EnumTipoPessoas.Funcionario) ? (
                                <Tooltip title="Consultar Cnpj">
                                  <Button
                                    variant="contained"
                                    color='primary'
                                    className={classes.buttonConsulta}
                                    onClick={() => props.consultarCnpj && props.consultarCnpj()}
                                  >
                                    <ProcurarIcon tipo="BUTTON_PRIMARY" />
                                  </Button>
                                </Tooltip>
                              ) : null}
                            </div>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Controller
                            name="nomeComercial"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                disabled={loading || isFuncionario}
                                allowSubmit={false}
                                inputRef={refInputNome}
                                label="Nome"
                                fullWidth
                                autoComplete={'off'}
                                helperText={
                                  errors.nomeComercial
                                    ? errors.nomeComercial.message
                                    : undefined
                                }
                                error={Boolean(errors.nomeComercial && errors.nomeComercial.message)}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        {(tipoFormPessoa === EnumTipoPessoas.Revendedor && !configuracoes && (tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse)) && (
                          <>
                            <Grid item xs={6}>
                              <Controller
                                name="diasLimiteAtrasoClientes"
                                control={control}
                                render={({ field }) => (
                                  <TextFieldSaurus
                                    disabled={loading}
                                    allowSubmit={false}
                                    tipo="NUMERO"
                                    label="Dias do Limite de Atraso de Clientes"
                                    fullWidth
                                    autoComplete={'off'}
                                    helperText={
                                      errors.diasLimiteAtrasoClientes
                                        ? errors.diasLimiteAtrasoClientes.message
                                        : undefined
                                    }
                                    error={Boolean(errors.diasLimiteAtrasoClientes && errors.diasLimiteAtrasoClientes.message)}
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Controller
                                name="tipoAdiantamentoFatura"
                                control={control}
                                render={({ field }) => (
                                  <SelectSaurus
                                    disabled={loading}
                                    allowSubmit={false}
                                    conteudo={TipoAdiantamentoMock}
                                    label="Adiantamento da Fatura"
                                    fullWidth
                                    autoComplete={'off'}
                                    helperText={
                                      errors.tipoAdiantamentoFatura
                                        ? errors.tipoAdiantamentoFatura.message
                                        : undefined
                                    }
                                    error={Boolean(errors.tipoAdiantamentoFatura && errors.tipoAdiantamentoFatura.message)}
                                    {...field}
                                    onChange={e => {
                                      const item = TipoAdiantamentoMock.find(x => x.Key === e.target.value)
                                      if (item) {
                                        setValue('tipoAdiantamentoFatura', item.Key)
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        )}
                        {(tipoFormPessoa === EnumTipoPessoas.Revendedor && !configuracoes && (tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse)) && (
                          <Grid item xs={12} md={6}>
                            <Controller
                              name="valorMinimoFatura"
                              control={control}
                              render={({ field }) => (
                                <TextFieldSaurus
                                  disabled={loading}
                                  allowSubmit={false}
                                  label="Valor Mínimo da Fatura"
                                  tipo="DECIMAL"
                                  positivo
                                  showStartAdornment
                                  fullWidth
                                  autoComplete={'off'}
                                  helperText={
                                    errors.valorMinimoFatura
                                      ? errors.valorMinimoFatura.message
                                      : undefined
                                  }
                                  error={Boolean(errors.valorMinimoFatura && errors.valorMinimoFatura.message)}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {tipoFormPessoa === EnumTipoPessoas.Revendedor && (
                          <Grid item xs={12} md={configuracoes ? 12 : 6}>
                            <Controller
                              name="telefoneSuporte"
                              control={control}
                              render={({ field }) => (
                                <TextFieldSaurus
                                  disabled={loading || isFuncionario}
                                  allowSubmit={false}
                                  id="telefoneSuporte"
                                  label="Telefone de Suporte"
                                  fullWidth
                                  tipo='TELEFONE'
                                  autoComplete={'off'}
                                  helperText={
                                    errors.telefoneSuporte
                                      ? errors.telefoneSuporte.message
                                      : undefined
                                  }
                                  error={Boolean(errors.telefoneSuporte && errors.telefoneSuporte.message)}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {(tipoFormPessoa === EnumTipoPessoas.Revendedor && tipoUsuario() === EnumTipoPessoas.SoftwareHouse) && (
                          <>
                            <Grid item xs={12} md={6}>
                              <Controller
                                name="tipoCobrancaRevenda"
                                control={control}
                                render={({ field }) => (
                                  <SelectSaurus
                                    disabled={loading}
                                    allowSubmit={false}
                                    conteudo={TipoCobrancaRevendaMock}
                                    label="Tipo de Faturamento"
                                    fullWidth
                                    autoComplete={'off'}
                                    helperText={
                                      errors.tipoCobrancaRevenda
                                        ? errors.tipoCobrancaRevenda.message
                                        : undefined
                                    }
                                    error={Boolean(errors.tipoCobrancaRevenda && errors.tipoCobrancaRevenda.message)}
                                    {...field}
                                    onChange={e => {
                                      const item = TipoCobrancaRevendaMock.find(x => x.Key === e.target.value)
                                      if (item) {
                                        setValue('tipoCobrancaRevenda', item.Key)
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Controller
                                name="avaliarDesempenhoRevenda"
                                control={control}
                                render={({ field }) => (
                                  <SelectSaurus
                                    disabled={loading}
                                    allowSubmit={false}
                                    conteudo={TipoAvaliacaoDesempenhoMock}
                                    label="Avaliação de Desempenho"
                                    fullWidth
                                    autoComplete={'off'}
                                    helperText={
                                      errors.avaliarDesempenhoRevenda
                                        ? errors.avaliarDesempenhoRevenda.message
                                        : undefined
                                    }
                                    error={Boolean(errors.avaliarDesempenhoRevenda && errors.avaliarDesempenhoRevenda.message)}
                                    {...field}
                                    onChange={e => {
                                      const item = TipoAvaliacaoDesempenhoMock.find(item => item.Key === e.target.value)
                                      if (item) {
                                        setValue('avaliarDesempenhoRevenda', item.Key)
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} lg={props.showClienteIdeal && showClienteIdealAdmin ? 8 : 12}>
                              <Grid container spacing={2}>
                                {(!tipoRevenda && getValues('tipo') !== EnumTipoPessoas.SoftwareHouse) && <Grid item xs={12} md={tipoFormPessoa === EnumTipoPessoas.Revendedor ? 6 : 12}>
                                  <Controller
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                      <SelectSaurus
                                        disabled={loading ||
                                          isFuncionario ||
                                          tipoFormPessoa === EnumTipoPessoas.Cliente}
                                        allowSubmit={false}
                                        label="Situação"
                                        fullWidth
                                        conteudo={SituacaoPessoaMock}
                                        autoComplete={'off'}
                                        helperText={
                                          errors.status
                                            ? errors.status.message
                                            : undefined
                                        }
                                        error={Boolean(errors.status && errors.status.message)}
                                        {...field}
                                        onChange={ev => {
                                          const item = SituacaoPessoaMock
                                            .filter(item => ev.target.value === item.Key)
                                          setValue('status', item[0].Key)
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>}
                                {(tipoFormPessoa === EnumTipoPessoas.Revendedor && tipoUsuario() === EnumTipoPessoas.SoftwareHouse && !configuracoes) && (
                                  <Grid item xs={12} md={6}>
                                    <Controller
                                      name="recebeIndicacoes"
                                      control={control}
                                      render={({ field }) => (
                                        <TextFieldSaurus
                                          disabled={loading}
                                          allowSubmit={false}
                                          id='recebeIndicacoes'
                                          label='Quilometragem Maxima da Indicação (KM)'
                                          tipo='NUMERO'
                                          fullWidth
                                          autoComplete={'off'}
                                          helperText={
                                            errors.recebeIndicacoes
                                              ? errors.recebeIndicacoes.message
                                              : undefined
                                          }
                                          error={Boolean(errors.recebeIndicacoes && errors.recebeIndicacoes.message)}
                                          {...field}
                                        />
                                      )}
                                    />
                                  </Grid>
                                )}
                                {!configuracoes && <Grid item xs={12}>
                                  <Controller
                                    name="classificacaoId"
                                    control={control}
                                    render={({ field }) => (
                                      <AutocompleteClassificacoes
                                        label='Classificação'
                                        value={getValues('classificacaoId') || undefined}
                                        defaultValue={model.classificacaoId || undefined}
                                        defaultName={model.classificacaoAbreviacao}
                                        fetchOnLoad
                                        disabled={isFuncionario}
                                        onChange={(e: any) => {
                                          setValue('classificacaoId', e.value.id)
                                        }}
                                        helperText={
                                          errors.classificacaoId
                                            ? errors.classificacaoId.message
                                            : undefined
                                        }
                                        error={Boolean(errors.classificacaoId && errors.classificacaoId.message)}
                                      />
                                    )}
                                  />
                                </Grid>}
                                {tipoFormPessoa === EnumTipoPessoas.Revendedor && (tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse) && (
                                  <Grid item xs={12}>
                                    <Controller
                                      name="gerenteId"
                                      control={control}
                                      render={({ field }) => (
                                        <AutocompleteGerentes
                                          label='Gerente Comercial'
                                          value={getValues('gerenteId') || undefined}
                                          defaultValue={model.gerenteId || undefined}
                                          defaultName={model.gerenteNome}
                                          fetchOnLoad
                                          onChange={(e: any) => {
                                            setValue('gerenteId', e.value.id)
                                          }}
                                          helperText={
                                            errors.gerenteId
                                              ? errors.gerenteId.message
                                              : undefined
                                          }
                                          error={Boolean(errors.gerenteId && errors.gerenteId.message)}
                                        />
                                      )}
                                    />
                                  </Grid>
                                )}
                                {(tipoFormPessoa === EnumTipoPessoas.Cliente && tipoUsuario() !== EnumTipoPessoas.Representante) && (
                                  <Grid item xs={12}>
                                    <Controller
                                      name="vendedorId"
                                      control={control}
                                      render={({ field }) => (
                                        <AutocompleteVendedor
                                          disabled={(tipoUsuario() !== EnumTipoPessoas.SoftwareHouse && !isEmpty(model.vendedorId)) || isFuncionario}
                                          label='Vendedor'
                                          revendedorId={getValues('revendaId') || undefined}
                                          value={getValues('vendedorId') || undefined}
                                          defaultValue={model.vendedorId || undefined}
                                          defaultName={model.vendedorNome}
                                          fetchOnLoad
                                          onChange={(e: any) => {
                                            setValue('vendedorId', e.value.id)
                                            setValue('vendedorNome', e.value.nomeComercial)
                                          }}
                                          helperText={
                                            errors.vendedorId
                                              ? errors.vendedorId.message
                                              : undefined
                                          }
                                          error={Boolean(errors.vendedorId && errors.vendedorId.message)}
                                        />
                                      )}
                                    />
                                  </Grid>
                                )}
                                {tipoFormPessoa === EnumTipoPessoas.Cliente && (tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse) && (
                                  <Grid item xs={12}>
                                    <Controller
                                      name="revendaId"
                                      control={control}
                                      render={({ field }) => (
                                        <AutocompleteRevendas
                                          label='Revendedor'
                                          value={getValues('revendaId') || undefined}
                                          defaultValue={model.revendaId || undefined}
                                          defaultName={model.revendaNome}
                                          fetchOnLoad
                                          onChange={(e: any) => {
                                            setValue('revendaId', e.value.id)
                                            setValue('revendaNome', e.value.nomeComercial)
                                          }}
                                          helperText={
                                            errors.revendaId
                                              ? errors.revendaId.message
                                              : undefined
                                          }
                                          error={Boolean(errors.revendaId && errors.revendaId.message)}
                                        />
                                      )}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                            {
                              props.showClienteIdeal && showClienteIdealAdmin ? (
                                <Grid item xs={12} lg={4}>
                                  <ClienteIdeal pessoaId={parametro} />
                                </Grid>
                              ) : null
                            }
                          </Grid>

                        </Grid>
                      </Grid>
                    </Grid>
                    {
                      props.showClienteIdeal && !showClienteIdealAdmin ? (
                        <Grid item xs={12} lg={4}>
                          <ClienteIdeal pessoaId={parametro} />
                        </Grid>
                      ) : null
                    }


                  </Grid>

                </Grid>



                {(!configuracoes && tipoFormPessoa === EnumTipoPessoas.Revendedor) && <Grid item xs={12}>
                  <Grid container spacing={2} className={classes.loginContainer}>
                    <Grid item xs={12}>
                      <Typography variant='h6' color='primary'>Login</Typography>
                      <Divider style={{ background: theme.palette.divider, marginTop: 5 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="login"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading || isFuncionario}
                            allowSubmit={false}
                            label="Usuário"
                            tipo="TEXTO"
                            fullWidth
                            helperText={
                              errors.login
                                ? errors.login.message
                                : undefined
                            }
                            error={Boolean(errors.login && errors.login.message)}
                            {...field}
                            autoComplete={'off'}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="senha"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            disabled={loading || isFuncionario}
                            allowSubmit={false}
                            label="Senha"
                            tipo='PASSWORD'
                            fullWidth
                            showPasswordVisibilityButton
                            helperText={
                              errors.senha
                                ? errors.senha.message
                                : undefined
                            }
                            error={Boolean(errors.senha && errors.senha.message)}
                            {...field}
                            autoComplete={'new-password'}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>}
                {podeLiberar && (
                  <Grid item xs={12}>
                    <Grid container spacing={2} className={classes.loginContainer}>
                      <Grid item xs={12}>
                        <Typography variant='h6' color='primary'>Liberação Provisória</Typography>
                        <Divider style={{ background: theme.palette.divider, marginTop: 5 }} />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="dataUltimaLiberacao"
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              disabled={carregando}
                              readOnly
                              label="Data da Última Liberação"
                              tipo="DATA_HORA"
                              allowSubmit
                              fullWidth
                              autoComplete={'off'}
                              helperText={
                                touchedFields.dataUltimaLiberacao && errors.dataUltimaLiberacao
                                  ? errors.dataUltimaLiberacao.message
                                  : undefined
                              }
                              error={Boolean(
                                errors.dataUltimaLiberacao && errors.dataUltimaLiberacao.message,
                              )}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="dataLimiteLiberacao"
                          control={control}
                          render={({ field }) => (
                            <TextFieldSaurus
                              disabled={carregando}
                              readOnly={tipoUsuario() !== EnumTipoPessoas.SoftwareHouse && tipoUsuario() !== EnumTipoPessoas.FinanceiroSoftwareHouse}
                              label="Data Limite para Liberação"
                              tipo="DATA_HORA"
                              allowSubmit
                              fullWidth
                              autoComplete={'off'}
                              helperText={
                                touchedFields.dataLimiteLiberacao && errors.dataLimiteLiberacao
                                  ? errors.dataLimiteLiberacao.message
                                  : undefined
                              }
                              error={Boolean(
                                errors.dataLimiteLiberacao && errors.dataLimiteLiberacao.message,
                              )}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          disabled={carregando}
                          onClick={() =>
                            props.handleLiberacaoProvisoria && props.handleLiberacaoProvisoria()
                          }
                          variant="outlined"
                          color="primary"
                          size="large"
                          fullWidth={isMobile}
                        >
                          <PessoaIcon tipo='BUTTON' />
                          Liberar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {configuracoes && (
                  <Grid item xs={12}>
                    <Controller
                      name="login"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={loading}
                          allowSubmit={false}
                          label="Usuário"
                          tipo="TEXTO"
                          fullWidth
                          autoComplete={'new-password'}
                          helperText={
                            touchedFields.login && errors.login
                              ? errors.login.message
                              : undefined
                          }
                          error={Boolean(errors.login && errors.login.message)}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                </Grid>
              </Grid>
              <Button style={{ display: 'none' }} type="submit"></Button>
              {loadingPin && <Box className={classes.loadingPinContainer}>
                <Box className={classes.loadingPin}>
                  <div ref={pinRef} className={classes.pinContainer}>
                    <div
                      className={classes.pin}
                      style={{ zIndex: 999 }}
                    >
                      <img
                        src={!isEmpty(img) ? img : SemImagem}
                        alt="Imagem do marcador"
                      />
                    </div>
                  </div>
                  <Typography color='textSecondary' variant='caption' style={{ marginTop: theme.spacing(2) }}>Carregando Imagem...</Typography>
                </Box>
              </Box>}
            </form>
          </div>
        </Box>
      </>
    );
  },
);
