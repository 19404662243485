import { Button, Fade, Grid, useTheme } from "@material-ui/core";
import { EnumTipoPessoas } from "model/enums/enum-tipo-pessoas";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useSessaoAtual } from "services/app";
import { AutocompleteIndicador } from "views/components/controles/autocompletes/autocomplete-indicador/autocomplete-indicador";
import { AutocompleteRevendas } from "views/components/controles/autocompletes/autocomplete-revendas/autocomplete-revendas";
import { TextFieldSaurus } from "views/components/controles/inputs";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { ProcurarIcon } from "views/components/icons";
import { useThemeQueries } from "views/theme";
import { PesquisaIndicacoesProps } from "./pesquisa-indicacoes-props";
import { useStyles } from "./pesquisa-indicacoes-styles";
import { StatusIndicacaoEmAndamentoMock, StatusIndicacaoFinalizadaMock, StatusIndicacaoValidacaoMock } from "data/mocks/situacao-status-indicacao-mock";

export const PesquisaIndicacoes = (props: PesquisaIndicacoesProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const { isMobile } = useThemeQueries();
    const { tipoUsuario } = useSessaoAtual()

    //STATES E REFS
    const [termoPesquisar, setTermoPesquisar] = useState('');
    const refInput = useRef<HTMLDivElement | null>(null);
    const [revendaId, setRevendaId] = useState<string>('')
    const [indicadorId, setIndicadorId] = useState<string>('');
    const [dataInicial, setDataInicial] = useState<string>('');
    const [dataFinal, setDataFinal] = useState<string>('');
    const [statusProcesso, setStatusProcesso] = useState(-1);

    //FOCO AO FICAR VISIVEL
    useEffect(() => {
        if (props.isOpened && !isMobile) refInput.current?.focus();
        else {
            setTermoPesquisar('');
        }
    }, [isMobile, props.isOpened]);

    //CHAMADA DO PESQUISAR
    const pesquisar = useCallback(
        (event: any) => {
            event.preventDefault();
            props.onPesquisar({ termo: termoPesquisar, revendaId: revendaId, indicadorId: indicadorId, dataInicial: dataInicial, dataFinal: dataFinal, statusProcesso: statusProcesso });
        },
        [dataFinal, dataInicial, indicadorId, props, revendaId, statusProcesso, termoPesquisar],
    );

    //ALTERAR A PESQUISA
    const onChangePesquisa = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setTermoPesquisar(event.target.value);
        },
        [],
    );

    return (
        <Fade in={props.isOpened} mountOnEnter unmountOnExit>
            <form onSubmit={pesquisar}>
                <Grid container className={classes.root} spacing={2}>
                    {tipoUsuario() === EnumTipoPessoas.SoftwareHouse && (
                        <Grid item xs={12} md={3}>
                            <div className={classes.containerFinal}>
                                <AutocompleteRevendas
                                    size="small"
                                    label="Revendas"
                                    value={revendaId}
                                    fullWidth={true}
                                    onChange={
                                        (e: any) => {
                                            setRevendaId(e.value.id)
                                        }
                                    }
                                />
                            </div>
                        </Grid>
                    )}
                    {tipoUsuario() === EnumTipoPessoas.SoftwareHouse && (
                        <Grid item xs={12} md={3}>
                            <div className={classes.containerFinal}>
                                <AutocompleteIndicador
                                    size="small"
                                    label="Agentes"
                                    fullWidth={true}
                                    value={indicadorId}
                                    onChange={
                                        (e: any) => {
                                            setIndicadorId(e.value.id)
                                        }
                                    }
                                />
                            </div>
                        </Grid>
                    )}
                    <Grid item md={3} xs={6}>
                        <TextFieldSaurus
                            size="small"
                            tipo="DATA"
                            label="Data Inicial"
                            value={dataInicial}
                            onChange={
                                (e: any) => {
                                    setDataInicial(e.target.value);
                                }
                            }
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextFieldSaurus
                            size="small"
                            tipo="DATA"
                            label='Data Final'
                            value={dataFinal}
                            onChange={
                                (e: any) => {
                                    setDataFinal(e.target.value)
                                }
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={tipoUsuario() === EnumTipoPessoas.SoftwareHouse ? 5 : 2}>
                        <SelectSaurus
                            conteudo={
                                (props.aba === 1
                                    ? StatusIndicacaoEmAndamentoMock
                                    : props.aba === 2
                                        ? StatusIndicacaoValidacaoMock
                                        : StatusIndicacaoFinalizadaMock)
                                    .filter(item => (tipoUsuario() !== EnumTipoPessoas.SoftwareHouse && tipoUsuario() !== EnumTipoPessoas.FinanceiroSoftwareHouse && tipoUsuario() !== EnumTipoPessoas.Revendedor) ? false : true)}
                            placeholder="Status"
                            size="small"
                            fullWidth={true}
                            value={statusProcesso}
                            divisoria={true}
                            onChange={(ev: any) => {
                                const item = props.aba === 1 ?
                                    StatusIndicacaoEmAndamentoMock
                                        .filter(item => item.Key === ev.target.value)
                                    :
                                    props.aba === 2 ?
                                        StatusIndicacaoValidacaoMock
                                            .filter(item => item.Key === ev.target.value)
                                        :
                                        StatusIndicacaoFinalizadaMock
                                            .filter(item => item.Key === ev.target.value)

                                setStatusProcesso(item[0].Key)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={tipoUsuario() === EnumTipoPessoas.SoftwareHouse ? 7 : 4} lg={tipoUsuario() === EnumTipoPessoas.SoftwareHouse ? 7 : 4}>
                        <div className={classes.containerFinal}>
                            <TextFieldSaurus
                                inputRef={refInput}
                                fullWidth
                                size="small"
                                allowSubmit={true}
                                value={termoPesquisar}
                                placeholder="Digite aqui alguma informação para procurar..."
                                onChange={onChangePesquisa}
                                adornmentColor={theme.palette.text.disabled}
                            />
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                className={tipoUsuario() === EnumTipoPessoas.SoftwareHouse ? '' : classes.btnPesquisa}
                            >
                                <ProcurarIcon tipo="BUTTON_PRIMARY" />
                                {tipoUsuario() === EnumTipoPessoas.SoftwareHouse ? 'Pesquisar' : ''}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </Fade>
    );
}