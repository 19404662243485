import { Grid, Tooltip, Typography } from '@material-ui/core';
import { SituacaoMock } from 'data/mocks/situacao-mock';
import { EnumSituacao } from 'model/enums/enum-situacao';
import { useCallback, useMemo } from 'react';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { toDateString } from 'utils/to-date';
import { useThemeQueries } from 'views/theme';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { CardProximaFaturaProps } from './card-proximas-fatura-props';
import { useStyles } from './card-proximas-fatura-styles'
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { toCurrency } from 'utils/toCurrency';

export const CardProximaFatura = ({
  model
}: CardProximaFaturaProps) => {
  const classes = useDefaultCardStyles();
  const listClasses = useStyles({
    descontoMaior: model.valorCusto < model.valorCustoOriginal,
    descontoMenor: model.valorCusto > model.valorCustoOriginal
  });
  const { theme, isMobile } = useThemeQueries()
  const { tipoUsuario } = useSessaoAtual();

  const isSoftwareHouse = useMemo(() => tipoUsuario() === EnumTipoPessoas.SoftwareHouse || tipoUsuario() === EnumTipoPessoas.FinanceiroSoftwareHouse,
    [tipoUsuario])

  const retornarCorStatus = useCallback((): string => {
    switch (model.situacao) {
      case EnumSituacao.Aberta:
        return theme.palette.info.main
      case EnumSituacao.Paga:
        return theme.palette.success.main
      case EnumSituacao.Atraso:
        return theme.palette.warning.main
      case EnumSituacao.Bonificada:
        return '#D7096C'
      case EnumSituacao.Cancelada:
        return theme.palette.error.main
      case EnumSituacao.Fechada:
        return '#8346a6'
    }
    return theme.palette.info.main
  }, [model.situacao, theme.palette.error.main, theme.palette.info.main, theme.palette.success.main, theme.palette.warning.main]);

  const descricao = SituacaoMock.filter(
    (x) => x.Key === model.situacao,
  )[0].Value;

  return (
    <>
      <DefaultCard>
        <Tooltip arrow title={descricao} placement="right">
          <div
            className={classes.tagStatus}
            style={{ background: retornarCorStatus() }}
          ></div>
        </Tooltip>
        {isMobile ? (
          <Grid container spacing={2} style={{
            padding: 8
          }}>
            <Grid item xs={6}>
              <Typography color="textPrimary" variant="caption">
                Nº {model.nossoNumero}
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
              >
                <b>{model.pagadorNome}</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textPrimary" variant="caption">
                CPF/CNPJ
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
              >
                {formatarCPFCNPJ(model.pagadorCpfCnpj)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textPrimary" variant="caption">
                Vencimento
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
              >
                <b>{toDateString(model.dataVencimento)}</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textPrimary" variant="caption" >
                Sistema
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
              >
                {model.sistemaDescricao}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textPrimary" variant="caption">
                Valor Custo
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
              >
                <b>{model.valorCusto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="textPrimary" variant="caption">
                Valor
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
              >
                <b>{model.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <div className={`${classes.cardContent} ${listClasses.container}`}>
            <div className={`${classes.celulaGridFull} ${listClasses.item}`} style={{ minWidth: 130, maxWidth: 130 }}>
              <Typography color="textPrimary" variant="caption">
                Nº {model.nossoNumero}
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
              >
                <b>{model.pagadorNome}</b>
              </Typography>
            </div>
            <div className={`${classes.celulaGrid} ${listClasses.item}`} style={{ marginRight: 10 }}>
              <Typography color="textPrimary" variant="caption">
                CPF/CNPJ
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
              >
                {formatarCPFCNPJ(model.pagadorCpfCnpj)}
              </Typography>
            </div>
            <div className={`${classes.celulaGrid} ${listClasses.item}`}>
              <Typography color="textPrimary" variant="caption">
                Vencimento
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
              >
                <b>{toDateString(model.dataVencimento)}</b>
              </Typography>
            </div>
            <div className={`${classes.celulaGridFull} ${listClasses.item}`} style={{ minWidth: 100, maxWidth: 100 }}>
              <Typography color="textPrimary" variant="caption" >
                Sistema
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
              >
                {model.sistemaDescricao}
              </Typography>
            </div>
            <div className={`${classes.celulaGrid} ${listClasses.item}`}>
              <Typography color="textPrimary" variant="caption">
                Valor Custo
              </Typography>
              {(model.valorCustoOriginal && model.valorCusto !== model.valorCustoOriginal) && <Typography className={listClasses.campoDesconto}>
                {toCurrency(model.valorCustoOriginal)}
              </Typography>}
              <Typography
                color="textPrimary"
                variant="body2"
              >
                <b>{model.valorCusto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
              </Typography>
            </div>
            {isSoftwareHouse && (
              <div className={`${classes.celulaGrid} ${listClasses.item}`}>
                <Typography color="textPrimary" variant="caption">
                  Valor
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  <b>{model.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
                </Typography>
              </div>
            )}
          </div>
        )}
      </DefaultCard>
    </>
  );
};
